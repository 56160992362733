import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useMainContext from "../useMainContext";
import { recommendationAPI } from "@/apis/RecommendationAPI/RecommendationAPI";
export var useRecommendations = function (params) { return useQuery({
    queryKey: ["".concat(params.recommendationStatus, "Recommendations")],
    queryFn: function () { return recommendationAPI.getRecommendations(params.recommendationStatus, params.franchiseId); },
    refetchOnMount: !params.useCachedData
}); };
export var useEntities = function (params) { return useQuery({
    queryKey: ["entities"],
    queryFn: function () { return recommendationAPI.getEntities(params.recommendationId, params.franchiseId); },
    refetchOnMount: !params.useCachedData
}); };
export var useEntity = function (entityRecommendationId, franchiseId) { return useQuery({
    queryKey: ["entity"],
    queryFn: function () {
        if (!entityRecommendationId || !franchiseId) {
            throw Error("Missing entityRecommendationId or franchiseId when fetching entity");
        }
        return recommendationAPI.getEntity(entityRecommendationId, franchiseId);
    },
    refetchOnMount: true
}); };
export var useItemsAndCategories = function (entityRecommendationId, filters) { return useQuery({
    queryKey: ["itemsAndCategories"],
    queryFn: function () { return recommendationAPI.getItemsAndCategories(entityRecommendationId, filters); },
    refetchOnMount: true
}); };
export var useUpdateItem = function (requestBody, itemId) {
    var updateEditionState = useMainContext().updateEditionState;
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function () { return recommendationAPI.updateItem(requestBody); },
        onSuccess: function () {
            queryClient.invalidateQueries({
                queryKey: ["itemsAndCategories"]
            });
        },
        onError: function () {
            updateEditionState("saving_error", itemId);
        }
    });
};
