import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
export var Container = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    maxHeight: 340,
    border: "1px solid #D9D9D9",
    borderRadius: 4,
    padding: 24
});
export var Header = styled(Box)({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    height: "80px",
    padding: 24,
    boxSizing: "border-box"
});
export var RangeContainer = styled(Box)({
    display: "flex",
    flexDirection: "row",
    gap: 8,
    alignItems: "center"
});
export var RangeKey = styled(Box)({
    width: 16,
    height: 16,
    borderRadius: 50,
    backgroundColor: "#056FAD"
});
export var RangeLabel = styled(Typography)({
    position: "absolute",
    fontSize: 12,
    fontWeight: 400,
    color: "#666666"
});
export var ActualValueContainer = styled(Typography)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 30,
    paddingBottom: 20
});
export var ValueLabel = styled(Typography, {
    shouldForwardProp: function (prop) { return prop !== "estimateAchieved"; }
})(function (_ref) {
    var _a = _ref.estimateAchieved, estimateAchieved = _a === void 0 ? true : _a;
    return {
        fontSize: "24px",
        fontWeight: "bold",
        color: estimateAchieved ? "#207F19" : "#DA291C"
    };
});
export var SpeedometerContainer = styled(Box, {
    shouldForwardProp: function (prop) { return prop !== "bottomPosition"; }
})(function (_ref2) {
    var _a = _ref2.bottomPosition, bottomPosition = _a === void 0 ? "13%" : _a;
    return {
        position: "absolute",
        bottom: bottomPosition,
        left: "50%",
        transformOrigin: "bottom center",
        width: "3px",
        transition: "transform 1s cubic-bezier(0.5, 1.50, 0.7, 1)",
        zIndex: 2
    };
});
