export var estimatedImpactStyles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    backgroundColor: "rgba(255, 255, 255, 1)",
    padding: "8px 24px",
    border: "1px solid rgba(235, 235, 235, 1)",
    borderRadius: "4px"
};
export var cardContainerStyles = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingTop: "1.5em",
    paddingBottom: "1.5em",
    gap: "1em"
};
export var cardStyles = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "150px"
};
