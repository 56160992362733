import styled from "@emotion/styled";
import { TableCell, tableCellClasses } from "@mui/material";
var StyledTableCell = styled(TableCell)(function (_ref) {
    var _a;
    var bordercolor = _ref.bordercolor, fontcolor = _ref.fontcolor;
    return _a = {},
        _a["&.".concat(tableCellClasses.body)] = {
            color: "".concat(fontcolor),
            fontSize: 16,
            fontFamily: "Open Sans",
            borderBottom: "1px solid ".concat(bordercolor),
            borderTop: "1px solid ".concat(bordercolor),
            "&:first-of-type": {
                borderTopLeftRadius: 4,
                borderBottomLeftRadius: 4,
                borderLeft: "1px solid ".concat(bordercolor)
            },
            "&:last-of-type": {
                borderTopRightRadius: 4,
                borderBottomRightRadius: 4,
                borderRight: "1px solid ".concat(bordercolor)
            }
        },
        _a;
});
export default StyledTableCell;
