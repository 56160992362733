import { constants } from "../../constants";
import { colors } from "../../lib/utilities";
//TIER DATA
export var tierGroupedHeaderData = [{
        id: 1,
        columnId: "sales",
        columnName: constants.table_grouped_header_sales,
        subColumns: [{
                id: 1,
                columnId: "sales_actual",
                columnName: constants.table_actual,
                dollarSign: true,
                visible: true
            }, {
                id: 2,
                columnId: "sales_estimated",
                columnName: constants.table_estimated,
                dollarSign: true,
                visible: true
            }, {
                id: 3,
                columnId: "sales_achieved",
                columnName: constants.table_achieved,
                tooltip: constants.table_achieved_tooltip,
                dollarSign: false,
                visible: true
            }, {
                id: 4,
                columnId: "sales_estimated_min",
                columnName: "",
                dollarSign: false,
                visible: false
            }, {
                id: 5,
                columnId: "sales_estimated_max",
                columnName: "",
                dollarSign: false,
                visible: false
            }],
        color: colors()[0]
    }, {
        id: 2,
        columnId: "gross_margin",
        columnName: constants.table_grouped_header_gross_margin,
        subColumns: [{
                id: 4,
                columnId: "gross_margin_actual",
                columnName: constants.table_actual,
                dollarSign: true,
                visible: true
            }, {
                id: 5,
                columnId: "gross_margin_estimated",
                columnName: constants.table_estimated,
                dollarSign: true,
                visible: true
            }, {
                id: 6,
                columnId: "gross_margin_achieved",
                columnName: constants.table_achieved,
                tooltip: constants.table_achieved_tooltip,
                dollarSign: false,
                visible: true
            }, {
                id: 7,
                columnId: "gross_margin_estimated_min",
                columnName: "",
                dollarSign: false,
                visible: false
            }, {
                id: 8,
                columnId: "gross_margin_estimated_max",
                columnName: "",
                dollarSign: false,
                visible: false
            }],
        color: colors()[1]
    }, {
        id: 3,
        columnId: "transaction_count",
        columnName: constants.table_grouped_header_transaction_count,
        subColumns: [{
                id: 7,
                columnId: "transaction_count_actual",
                columnName: constants.table_actual,
                dollarSign: true,
                visible: true
            }, {
                id: 8,
                columnId: "transaction_count_estimated",
                columnName: constants.table_estimated,
                dollarSign: true,
                visible: true
            }, {
                id: 9,
                columnId: "transaction_count_achieved",
                columnName: constants.table_achieved,
                tooltip: constants.table_achieved_tooltip,
                dollarSign: false,
                visible: true
            }, {
                id: 10,
                columnId: "transaction_count_estimated_min",
                columnName: "",
                dollarSign: false,
                visible: false
            }, {
                id: 11,
                columnId: "transaction_count_estimated_max",
                columnName: "",
                dollarSign: false,
                visible: false
            }],
        color: colors()[2]
    }];
export var tierHeaderData = [{
        id: 1,
        columnId: "id",
        columnName: "id",
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 200,
        isLink: true,
        dollarSign: false,
        visible: false
    }, {
        id: 2,
        columnId: "tier",
        columnName: constants.table_tier,
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 200,
        isLink: true,
        dollarSign: false,
        visible: true
    }];
export var tierRows = [{
        id: 1,
        tier: "Pricing tier 1",
        sales_actual: 7,
        sales_estimated: "$1 - $10",
        sales_estimated_min: 1,
        sales_estimated_max: 10,
        sales_achieved: true,
        gross_margin_actual: 5.5,
        gross_margin_estimated: "$7 - $9",
        gross_margin_estimated_min: 7,
        gross_margin_estimated_max: 9,
        gross_margin_achieved: false,
        transaction_count_actual: 6.8,
        transaction_count_estimated: "$5 - $9",
        transaction_count_estimated_min: 5,
        transaction_count_estimated_max: 9,
        transaction_count_achieved: true
    }, {
        id: 2,
        tier: "Pricing tier 2",
        sales_actual: 10,
        sales_estimated: "$3 - $16",
        sales_estimated_min: 3,
        sales_estimated_max: 16,
        sales_achieved: true,
        gross_margin_actual: 4,
        gross_margin_estimated: "$3 - $12",
        gross_margin_estimated_min: 3,
        gross_margin_estimated_max: 12,
        gross_margin_achieved: false,
        transaction_count_actual: 5,
        transaction_count_estimated: "$2 - $15",
        transaction_count_estimated_min: 2,
        transaction_count_estimated_max: 15,
        transaction_count_achieved: false
    }, {
        id: 3,
        tier: "Pricing tier 3",
        sales_actual: 4,
        sales_estimated: "$8 -$14",
        sales_estimated_min: 8,
        sales_estimated_max: 14,
        sales_achieved: false,
        gross_margin_actual: 4,
        gross_margin_estimated: "$10 - $20",
        gross_margin_estimated_min: 10,
        gross_margin_estimated_max: 20,
        gross_margin_achieved: false,
        transaction_count_actual: 5,
        transaction_count_estimated: "$11 - $18",
        transaction_count_estimated_min: 11,
        transaction_count_estimated_max: 18,
        transaction_count_achieved: false
    }];
//STORE DATA
export var storeHeaderData = [{
        id: 1,
        columnId: "id",
        columnName: "id",
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 200,
        isLink: true,
        dollarSign: false,
        visible: false
    }, {
        id: 2,
        columnId: "storeId",
        columnName: constants.table_storeId,
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 200,
        isLink: true,
        dollarSign: false,
        visible: true
    }];
export var storeGroupedHeaderData = [{
        id: 1,
        columnId: "sales",
        columnName: constants.table_grouped_header_sales,
        subColumns: [{
                id: 10,
                columnId: "sales_actual",
                columnName: constants.table_actual,
                dollarSign: true,
                visible: true
            }, {
                id: 11,
                columnId: "sales_estimated",
                columnName: constants.table_estimated,
                dollarSign: true,
                visible: true
            }, {
                id: 12,
                columnId: "sales_achieved",
                columnName: constants.table_achieved,
                tooltip: constants.table_achieved_tooltip,
                dollarSign: false,
                visible: true
            }, {
                id: 13,
                columnId: "sales_estimated_min",
                columnName: "",
                dollarSign: false,
                visible: false
            }],
        color: colors()[0]
    }, {
        id: 2,
        columnId: "gross_margin",
        columnName: constants.table_grouped_header_gross_margin,
        subColumns: [{
                id: 13,
                columnId: "gross_margin_actual",
                columnName: constants.table_actual,
                dollarSign: true,
                visible: true
            }, {
                id: 14,
                columnId: "gross_margin_estimated",
                columnName: constants.table_estimated,
                dollarSign: true,
                visible: true
            }, {
                id: 15,
                columnId: "gross_margin_achieved",
                columnName: constants.table_achieved,
                tooltip: constants.table_achieved_tooltip,
                dollarSign: false,
                visible: true
            }, {
                id: 16,
                columnId: "gross_margin_estimated_min",
                columnName: "",
                dollarSign: false,
                visible: false
            }, {
                id: 17,
                columnId: "gross_margin_estimated_max",
                columnName: "",
                dollarSign: false,
                visible: false
            }],
        color: colors()[1]
    }, {
        id: 3,
        columnId: "transaction_count",
        columnName: constants.table_grouped_header_transaction_count,
        subColumns: [{
                id: 16,
                columnId: "transaction_count_actual",
                columnName: constants.table_actual,
                dollarSign: true,
                visible: true
            }, {
                id: 17,
                columnId: "transaction_count_estimated",
                columnName: constants.table_estimated,
                dollarSign: true,
                visible: true
            }, {
                id: 18,
                columnId: "transaction_count_achieved",
                columnName: constants.table_achieved,
                tooltip: constants.table_achieved_tooltip,
                dollarSign: false,
                visible: true
            }, {
                id: 19,
                columnId: "transaction_count_estimated_min",
                columnName: "",
                dollarSign: false,
                visible: false
            }, {
                id: 20,
                columnId: "transaction_count_estimated_max",
                columnName: "",
                dollarSign: false,
                visible: false
            }],
        color: colors()[2]
    }];
export var storeRows = [{
        id: 10,
        storeId: 122359,
        sales_actual: 70,
        sales_estimated: "$60 - $90",
        sales_estimated_min: 60,
        sales_estimated_max: 90,
        sales_achieved: true,
        gross_margin_actual: 159,
        gross_margin_estimated: "$160 - $190",
        gross_margin_estimated_min: 160,
        gross_margin_estimated_max: 190,
        gross_margin_achieved: false,
        transaction_count_actual: 80,
        transaction_count_estimated: "$50 - $90",
        transaction_count_estimated_min: 50,
        transaction_count_estimated_max: 90,
        transaction_count_achieved: true
    }, {
        id: 20,
        storeId: 23333437,
        sales_actual: 237,
        sales_estimated: "$190 - $240",
        sales_estimated_min: 190,
        sales_estimated_max: 240,
        sales_achieved: true,
        gross_margin_actual: 237,
        gross_margin_estimated: "$190 - $240",
        gross_margin_estimated_min: 190,
        gross_margin_estimated_max: 240,
        gross_margin_achieved: true,
        transaction_count_actual: 237,
        transaction_count_estimated: "$190 - $240",
        transaction_count_estimated_min: 190,
        transaction_count_estimated_max: 240,
        transaction_count_achieved: true
    }];
//MENU ITEM DATA
export var menuItemHeaderData = [{
        id: 1,
        columnId: "id",
        columnName: "id",
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 200,
        isLink: true,
        dollarSign: false,
        visible: false
    }, {
        id: 2,
        columnId: "category",
        columnName: constants.table_cotegory,
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 165,
        visible: true
    }, {
        id: 3,
        columnId: "menuItem",
        columnName: constants.table_menuItem,
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 220,
        visible: true
    }, {
        id: 4,
        columnId: "itemID",
        columnName: constants.table_itemID,
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 165,
        visible: true
    }, {
        id: 5,
        columnId: "new_price",
        columnName: constants.table_new_price,
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 165,
        dollarSign: true,
        visible: true
    }, {
        id: 6,
        columnId: "old_price",
        columnName: constants.table_old_price,
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 165,
        dollarSign: true,
        visible: true
    }];
export var menuItemGroupedHeaderData = [{
        id: 1,
        columnId: "sales",
        columnName: constants.table_grouped_header_sales,
        subColumns: [{
                id: 19,
                columnId: "sales_actual",
                columnName: constants.table_actual,
                dollarSign: true,
                visible: true
            }, {
                id: 20,
                columnId: "sales_estimated",
                columnName: constants.table_estimated,
                dollarSign: true,
                visible: true
            }, {
                id: 21,
                columnId: "sales_achieved",
                columnName: constants.table_achieved,
                tooltip: constants.table_achieved_tooltip,
                dollarSign: false,
                visible: true
            }, {
                id: 22,
                columnId: "sales_estimated_min",
                columnName: "",
                dollarSign: false,
                visible: false
            }, {
                id: 23,
                columnId: "sales_estimated_max",
                columnName: "",
                dollarSign: false,
                visible: false
            }],
        color: colors()[0]
    }, {
        id: 2,
        columnId: "gross_margin",
        columnName: constants.table_grouped_header_gross_margin,
        subColumns: [{
                id: 22,
                columnId: "gross_margin_actual",
                columnName: constants.table_actual,
                dollarSign: true,
                visible: true
            }, {
                id: 23,
                columnId: "gross_margin_estimated",
                columnName: constants.table_estimated,
                dollarSign: true,
                visible: true
            }, {
                id: 24,
                columnId: "gross_margin_achieved",
                columnName: constants.table_achieved,
                tooltip: constants.table_achieved_tooltip,
                dollarSign: false,
                visible: true
            }, {
                id: 25,
                columnId: "gross_margin_estimated_min",
                columnName: "",
                dollarSign: false,
                visible: false
            }, {
                id: 26,
                columnId: "gross_margin_estimated_max",
                columnName: "",
                dollarSign: false,
                visible: false
            }],
        color: colors()[1]
    }];
export var menuItemRows = [{
        id: 100,
        category: "Frozen yoghurt",
        menuItem: "Ice cream sandwich",
        itemID: 4,
        new_price: 4.0,
        old_price: 3.5,
        sales_actual: 159,
        sales_estimated: "$60 - $190",
        sales_estimated_min: 60,
        sales_estimated_max: 190,
        sales_achieved: true,
        gross_margin_actual: 159,
        gross_margin_estimated: "$160 - $190",
        gross_margin_estimated_min: 160,
        gross_margin_estimated_max: 190,
        gross_margin_achieved: false
    }, {
        id: 200,
        category: "Ice cream sandwich",
        menuItem: "Eclair",
        itemID: 4.3,
        new_price: 4.0,
        old_price: 3.5,
        sales_actual: 237,
        sales_estimated: "$190 - $240",
        sales_estimated_min: 190,
        sales_estimated_max: 240,
        sales_achieved: true,
        gross_margin_actual: 237,
        gross_margin_estimated: "$190 - $240",
        gross_margin_estimated_min: 190,
        gross_margin_estimated_max: 240,
        gross_margin_achieved: true
    }];
//RECOMMENDATION DATA
export var newRecommendationHeaderData = [{
        id: 1,
        columnId: "recommendationId",
        columnName: "id",
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 200,
        isLink: false,
        dollarSign: false,
        visible: false,
        sort: false
    }, {
        id: 2,
        columnId: "recommendationName",
        columnName: constants.table_recommendation_name,
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 200,
        isLink: true,
        dollarSign: false,
        visible: true,
        sort: true
    }, {
        id: 3,
        columnId: "dueDate",
        columnName: constants.table_due_date,
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 200,
        isLink: false,
        dollarSign: false,
        visible: true,
        sort: false
    }, {
        id: 4,
        columnId: "dateSubmitted",
        columnName: constants.table_submitted_date,
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 200,
        isLink: false,
        dollarSign: false,
        visible: false,
        sort: false
    }, {
        id: 5,
        columnId: "nroOfTiers",
        columnName: constants.table_nro_of_tiers,
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 200,
        isLink: false,
        dollarSign: false,
        visible: true,
        sort: false
    }, {
        id: 6,
        columnId: "actionButton",
        columnName: "",
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 200,
        isLink: false,
        dollarSign: false,
        visible: true,
        sort: false,
        actionButton: "Review"
    }];
export var newRecommendationRows = [{
        recommendationId: 1,
        recommendationName: "Q1 2023",
        dueDate: "2023-01-01",
        dateSubmitted: "2023-04-01",
        nroOfTiers: 3,
        actionButton: "Review"
    }, {
        recommendationId: 2,
        recommendationName: "Q2 2023",
        dueDate: "2023-04-01",
        dateSubmitted: "2023-07-01",
        nroOfTiers: 3,
        actionButton: "Review"
    }, {
        recommendationId: 3,
        recommendationName: "Q3 2023",
        dueDate: "2023-07-01",
        dateSubmitted: "2023-10-01",
        nroOfTiers: 3,
        actionButton: "Review"
    }, {
        recommendationId: 4,
        recommendationName: "Q4 2023",
        dueDate: "2023-10-01",
        dateSubmitted: "2024-01-01",
        nroOfTiers: 3,
        actionButton: "Review"
    }];
export var pastRecommendationHeaderData = [{
        id: 1,
        columnId: "recommendationId",
        columnName: "id",
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 200,
        isLink: false,
        dollarSign: false,
        visible: false,
        sort: false
    }, {
        id: 2,
        columnId: "recommendationName",
        columnName: constants.table_recommendation_name,
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 200,
        isLink: true,
        dollarSign: false,
        visible: true,
        sort: true
    }, {
        id: 3,
        columnId: "dueDate",
        columnName: constants.table_due_date,
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 200,
        isLink: false,
        dollarSign: false,
        visible: false,
        sort: false
    }, {
        id: 4,
        columnId: "dateSubmitted",
        columnName: constants.table_submitted_date,
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 200,
        isLink: false,
        dollarSign: false,
        visible: true,
        sort: false
    }, {
        id: 5,
        columnId: "nroOfTiers",
        columnName: constants.table_nro_of_tiers,
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 200,
        isLink: false,
        dollarSign: false,
        visible: true,
        sort: false
    }, {
        id: 6,
        columnId: "actionButton",
        columnName: "",
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 200,
        isLink: false,
        dollarSign: false,
        visible: true,
        sort: false,
        actionButton: ""
    }];
export var pastRecommendationRows = [{
        recommendationId: 1,
        recommendationName: "Q1 2021",
        dueDate: "Jun 01, 2021",
        dateSubmitted: "Apr 01, 2021",
        nroOfTiers: 3,
        actionButton: ""
    }, {
        recommendationId: 2,
        recommendationName: "Q2 2021",
        dueDate: "Sep 01, 2021",
        dateSubmitted: "Jul 01, 2021",
        nroOfTiers: 3,
        actionButton: ""
    }, {
        recommendationId: 3,
        recommendationName: "Q3 2021",
        dueDate: "Dec 01, 2021",
        dateSubmitted: "Oct 01, 2021",
        nroOfTiers: 3,
        actionButton: ""
    }, {
        recommendationId: 4,
        recommendationName: "Q4 2021",
        dueDate: "Mar 01, 2022",
        dateSubmitted: "Jan 01, 2022",
        nroOfTiers: 3,
        actionButton: ""
    }, {
        recommendationId: 5,
        recommendationName: "Q1 2022",
        dueDate: "Jun 01, 2022",
        dateSubmitted: "Apr 01, 2022",
        nroOfTiers: 3,
        actionButton: ""
    }, {
        recommendationId: 6,
        recommendationName: "Q2 2022",
        dueDate: "Sep 01, 2022",
        dateSubmitted: "Jul 01, 2022",
        nroOfTiers: 3,
        actionButton: ""
    }, {
        recommendationId: 7,
        recommendationName: "Q3 2022",
        dueDate: "Dec 01, 2022",
        dateSubmitted: "Oct 01, 2022",
        nroOfTiers: 3,
        actionButton: ""
    }, {
        recommendationId: 8,
        recommendationName: "Q4 2022",
        dueDate: "Mar 01, 2023",
        dateSubmitted: "Jan 01, 2023",
        nroOfTiers: 3,
        actionButton: ""
    }, {
        recommendationId: 9,
        recommendationName: "Q1 2023",
        dueDate: "Jun 01, 2023",
        dateSubmitted: "Apr 01, 2023",
        nroOfTiers: 3,
        actionButton: ""
    }, {
        recommendationId: 10,
        recommendationName: "Q2 2023",
        dueDate: "Sep 01, 2023",
        dateSubmitted: "Jul 01, 2023",
        nroOfTiers: 3,
        actionButton: ""
    }, {
        recommendationId: 11,
        recommendationName: "Q3 2023",
        dueDate: "Dec 01, 2023",
        dateSubmitted: "Oct 01, 2023",
        nroOfTiers: 3,
        actionButton: ""
    }, {
        recommendationId: 12,
        recommendationName: "Q4 2023",
        dueDate: "Mar 01, 2024",
        dateSubmitted: "Jan 01, 2024",
        nroOfTiers: 3,
        actionButton: ""
    }, {
        recommendationId: 13,
        recommendationName: "Q1 2024",
        dueDate: "Jun 01, 2024",
        dateSubmitted: "Apr 01, 2024",
        nroOfTiers: 3,
        actionButton: ""
    }, {
        recommendationId: 14,
        recommendationName: "Q2 2024",
        dueDate: "Sep 01, 2024",
        dateSubmitted: "Jul 01, 2024",
        nroOfTiers: 3,
        actionButton: ""
    }, {
        recommendationId: 15,
        recommendationName: "Q3 2024",
        dueDate: "Dec 01, 2024",
        dateSubmitted: "Oct 01, 2024",
        nroOfTiers: 3,
        actionButton: ""
    }, {
        recommendationId: 16,
        recommendationName: "Q4 2024",
        dueDate: "Mar 01, 2025",
        dateSubmitted: "Jan 01, 2025",
        nroOfTiers: 3,
        actionButton: ""
    }, {
        recommendationId: 17,
        recommendationName: "Q1 2025",
        dueDate: "Jun 01, 2025",
        dateSubmitted: "Apr 01, 2025",
        nroOfTiers: 3,
        actionButton: ""
    }, {
        recommendationId: 18,
        recommendationName: "Q2 2025",
        dueDate: "Sep 01, 2025",
        dateSubmitted: "Jul 01, 2025",
        nroOfTiers: 3,
        actionButton: ""
    }, {
        recommendationId: 19,
        recommendationName: "Q3 2025",
        dueDate: "Dec 01, 2025",
        dateSubmitted: "Oct 01, 2025",
        nroOfTiers: 3,
        actionButton: ""
    }, {
        recommendationId: 20,
        recommendationName: "Q4 2025",
        dueDate: "Mar 01, 2026",
        dateSubmitted: "Jan 01, 2026",
        nroOfTiers: 3,
        actionButton: ""
    }, {
        recommendationId: 21,
        recommendationName: "Q1 2026",
        dueDate: "Jun 01, 2026",
        dateSubmitted: "Apr 01, 2026",
        nroOfTiers: 3,
        actionButton: ""
    }];
export var currentRecommendationHeaderData = [{
        id: 1,
        columnId: "tierId",
        columnName: "id",
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 200,
        isLink: false,
        dollarSign: false,
        visible: false,
        sort: false
    }, {
        id: 2,
        columnId: "tierName",
        columnName: "Tier",
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 200,
        isLink: true,
        dollarSign: false,
        visible: true,
        sort: true
    }, {
        id: 3,
        columnId: "storesInTier",
        columnName: "Stores",
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 200,
        isLink: true,
        dollarSign: false,
        visible: true,
        sort: false
    }, {
        id: 4,
        columnId: "priceChange",
        columnName: "Price Change",
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 200,
        isLink: false,
        dollarSign: false,
        visible: true,
        sort: false
    }, {
        id: 5,
        columnId: "marginImpact",
        columnName: "Margin Impact",
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 200,
        isLink: false,
        dollarSign: false,
        visible: true,
        sort: false
    }, {
        id: 6,
        columnId: "transactionImpact",
        columnName: "Transaction Impact",
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 200,
        isLink: false,
        dollarSign: false,
        visible: true,
        sort: false
    }, {
        id: 8,
        columnId: "actionButton",
        columnName: "",
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 200,
        isLink: false,
        dollarSign: false,
        visible: true,
        sort: false,
        actionButton: {
            primary: "Submit",
            secondary: "Review"
        }
    }];
export var currentRecommendationRows = [{
        tierId: 1,
        tierName: "Tier 1 2023",
        storesInTier: "2 Stores",
        priceChange: "0.18%",
        marginImpact: "$180 - $230",
        transactionImpact: "(0.5%) - (0.1%)",
        costImpact: "0.3%",
        actionButton: {
            primary: "Submit",
            secondary: "Review"
        }
    }, {
        tierId: 2,
        tierName: "Tier 2 2023",
        storesInTier: "20 Stores",
        priceChange: "0.98%",
        marginImpact: "$180 - $230",
        transactionImpact: "(0.5%) - (0.1%)",
        costImpact: "0.3%",
        actionButton: {
            primary: "Submit",
            secondary: "Review"
        }
    }, {
        tierId: 3,
        tierName: "Tier 3 2023",
        storesInTier: "23 Stores",
        priceChange: "0.18%",
        marginImpact: "$180 - $230",
        transactionImpact: "(0.5%) - (0.1%)",
        costImpact: "0.3%",
        actionButton: {
            primary: "Submit",
            secondary: "Review"
        }
    }, {
        tierId: 4,
        tierName: "Tier 4 2023",
        storesInTier: "23 Stores",
        priceChange: "0.18%",
        marginImpact: "$180 - $230",
        transactionImpact: "(0.5%) - (0.1%)",
        costImpact: "0.3%",
        actionButton: {
            primary: "Submit",
            secondary: "Review"
        }
    }, {
        tierId: 5,
        tierName: "Tier 5 2023",
        storesInTier: "23 Stores",
        priceChange: "0.18%",
        marginImpact: "$180 - $230",
        transactionImpact: "(0.5%) - (0.1%)",
        costImpact: "0.3%",
        actionButton: {
            primary: "Submit",
            secondary: "Review"
        }
    }];
export var storeListHeaderData = [{
        id: 1,
        columnId: "store",
        columnName: "Store ID",
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 100,
        isLink: false,
        dollarSign: false,
        visible: true,
        sort: true
    }, {
        id: 2,
        columnId: "street",
        columnName: "Street",
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 100,
        isLink: false,
        dollarSign: false,
        visible: true,
        sort: true
    }, {
        id: 3,
        columnId: "city",
        columnName: "City",
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 100,
        isLink: false,
        dollarSign: false,
        visible: true,
        sort: true
    }, {
        id: 4,
        columnId: "state",
        columnName: "State",
        color: "rgba(53, 151, 193, 0.04)",
        minWidth: 100,
        isLink: false,
        dollarSign: false,
        visible: true,
        sort: true
    }];
export var storeListRows = [{
        store: 14296,
        street: "234 Hickory",
        city: "Little Rock",
        state: "Arkansas"
    }, {
        store: 21947,
        street: "234 Hickory",
        city: "Bryant",
        state: "Arkansas"
    }, {
        store: 29364,
        street: "123 Cypress",
        city: "Benton",
        state: "Arkansas"
    }, {
        store: 36471,
        street: "456 Birch",
        city: "Little Rock",
        state: "Arkansas"
    }, {
        store: 52913,
        street: "113 Frankfurt",
        city: "Little Rock",
        state: "Arkansas"
    }, {
        store: 67538,
        street: "113 Frankfurt",
        city: "Conway",
        state: "Arkansas"
    }];
