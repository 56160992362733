export var UNAUTHORIZED_ERROR_MESSAGE = "You are not authorized to execute this operation. " + "Please re-login or contact administrator";
export var constants = {
    close: "CLOSE",
    loading: "Loading...",
    // Home.tsx
    welcome: "Welcome back, ",
    first_tab_name: "Overview",
    second_tab_name: "Price Recommendations",
    third_tab_name: "Competitor Prices",
    fourth_tab_name: "Price Performance",
    fifth_tab_name: "Settings",
    due_date: "Round Closure Date: ",
    submit_button: "SUBMIT TIER",
    submitted_button: "SUBMITTED",
    submitted_button_info: "Submitted by ",
    // Overview
    overview_recommendation_title: "Recommendation Available!",
    view_recommendation: "View Recommendation",
    overview_competitor_prices_title: "Competitor Prices Summary",
    view_competitor_prices: "View Competitor Prices",
    price_change_tooltip: "Weighted average percentage change in price, weighted on units sold",
    margin_impact_tooltip: "Comparison of current weekly margin vs. estimated weekly margin due to the price changes",
    transaction_impact_tooltip: "Comparison of current weekly transactions vs. estimated weekly transactions due to the price changes",
    cost_impact_tooltip: "Comparison of current weekly F&P costs vs. expected weekly F&P cost changes",
    competitor_prices_title: "Competitor Prices Summary",
    baseline: "Baseline: ",
    lastUpdate: "Last updated: ",
    //Estimated Impact
    price_change_label: "Price Change",
    margin_impact_label: "Margin Impact",
    transaction_impact_label: "Transaction Impact",
    //PriceRecommendation
    new_recommendation: "New Recommendations",
    past_recommendations: "Past Recommendations",
    table_recommendation_name: "Recommendation",
    table_due_date: "Round Closure Date",
    table_submitted_date: "Date Submitted",
    table_nro_of_tiers: "Tiers",
    review_msg_tier: "Please review each price tier and submit when ready. " + "To edit offline, download the data, make changes and upload the updated data.",
    view_stores: "View Stores",
    secondary_empty_state_message: "Your pricing recommendations will appear here soon.",
    review: "Review",
    //PriceEdition
    price_edition_table_title: "Items to Review",
    upload_new_prices_action: "Upload New Prices",
    download_prices_action: "CSV",
    reset_default_action: "Reset to Default",
    group_leader_tooltip: "A group leader is an item whose price " + "changes affect related items in the same group.",
    saving_msg: "Saving...",
    saving_error: "Saving failed.",
    validation_error: "Invalid price.",
    retry: "Retry",
    save_with_warnings: "Save",
    large_price_change_warning: "Large change (+/-25%)",
    zero_price_warning: "Price is $0.00",
    //PricePerformance
    price_performance_title: "Welcome to Price Performance",
    price_performance_subtitle: "Your reports will appear here soon.",
    table_tier: "Tier",
    tier_main_title: "Performance Summary",
    tier_main_empty_title: "Overview",
    tier_section_title: "Performance by Store",
    tier_inner_section_message: "Not available",
    table_actual: "Actual",
    table_estimated: "Estimated",
    table_achieved: "Achieved",
    table_achieved_tooltip: "A check mark means that the actual " + "value met or exceeded the estimated benenfit.",
    table_storeId: "Store ID",
    table_cotegory: "Category",
    table_menuItem: "Menu Item",
    table_itemID: "Item ID",
    table_new_price: "New Price",
    table_old_price: "Old Price",
    table_grouped_header_sales: "Sales",
    table_grouped_header_gross_margin: "Gross Margin",
    table_grouped_header_transaction_count: "Transaction Count",
    table_grouped_header_transaction_count_change: "Transaction Count Change",
    //AccountSettings
    inner_section_message: "Not available",
    account_settings_first_panel: "Pricing Advisor(s)",
    account_settings_second_panel: "User Management",
    account_settings_assignment_title: "Store Tier Assignment",
    hours_of_operation: "Hours of Operation",
    organisation_banner: "To make changes to Settings, please contact your pricing advisor.",
    //CompetitorPrices
    your_stores: "Your Stores",
    map_instructions: "To view competitors, zoom in, click on clusters, " + "or use filters to find and select a store.",
    estimated_impact: "Estimated Impact",
    review_msg: "Review the prices in the table below. Submit the price tier when finished.",
    items_to_review: "Items to Review",
    nearby_competitors_banner: "To compare prices select up to 5 competitors.",
    tiers_to_review: "Tiers to Review",
    submit_all_tiers: "Submit All Tiers",
    submit_one_tier: "Submit",
    pricing: "Price",
    stores: "Stores",
    csv: "csv",
    empty_competitors_message: "There is no competitor data for this store at this time",
    // File Upload Modal
    file_upload_modal_title: "Upload New Prices for All Tiers",
    validating_file: "Validating File",
    upload_successful: "File successfully uploaded!",
    cancel: "Cancel",
    apply_changes: "Apply",
    upload_instructions: "Edit only the 'SUBMITTED PRICE' column. \n    Please ensure all other columns and rows remain unchanged to avoid data issues.",
    download_prices: "Download Prices",
    file_restrictions: "Format allowed .csv (max. 30MB)",
    critical_error_subtitle: "We found some issues",
    go_back: "go back",
    // Items Filter Bar
    item_id_search: "Item ID",
    menu_item_search: "Menu Item",
    category_dropdown_label: "Category",
    sort_by_dropdown_label: "Sort By Column",
    order_by_dropdown_label: "Order By",
    radio_group_label: "Show Items: ",
    menu_item_description: "Menu Item Description",
    menu_item_or_item_id_search: "Menu Item or Item ID",
    apply_filters: "Apply",
    // reset default modal
    reset_default_modal_title: "Are you sure?",
    reset_default_modal_body: "Clicking continue will reset the prices of all items in the table based on the filters that are " + "currently applied.",
    continue: "Continue",
    // Group Leader modal
    group_leader_title: "Group Leader",
    group_leader_related_items: "Related Items",
    group_leader_price_details: "Price Details",
    close_modal: "Close",
    submit: "Submit",
    submit_tier_desc: "Are you sure you want to submit this price tier? This action will lock in all price changes.",
    yes_submit: "Yes, SUBMIT",
    submitting: "Submitting",
    successfully_submitted: "Successfully Submitted!",
    return_to_recommendation: "Return to recommendation",
    submit_all_tiers_desc: "Are you sure you want to submit all price tiers? This action will lock in all price changes.",
    submitting_all_tiers: "Submitting All Tiers...",
    all_tiers_submitted: "All Tiers were Successfully Submitted!",
    return_to_home: "Return to home",
    no_data_available: "No data available",
    // common
    default_error_message: "An unexpected error occurred",
    go_home: "Go Home"
};
