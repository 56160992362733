import { useState, useEffect } from "react";
export var useWindowDimensions = function () {
    var _a = useState({
        width: window.innerWidth,
        height: window.innerHeight
    }), dimensions = _a[0], setDimensions = _a[1];
    useEffect(function () {
        var handleResize = function () {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };
        window.addEventListener("resize", handleResize);
        return function () { return window.removeEventListener("resize", handleResize); };
    }, []);
    return dimensions;
};
