import { useQuery } from "@tanstack/react-query";
import { pricePerformanceAPI } from "@/apis/PricePerformanceAPI/PricePerformanceAPI";
export var usePriceRecommendationsSummary = function (params) { return useQuery({
    queryKey: ["priceRecommendationSummary"],
    queryFn: function () { return pricePerformanceAPI.getRecommendationSummary(params.franchiseId, params.recommendationRoundId); },
    refetchOnMount: !params.useCachedData
}); };
export var useGetPricePerformanceEntities = function (params) { return useQuery({
    queryKey: ["pricePerformanceEntities"],
    queryFn: function () { return pricePerformanceAPI.getPricePerformanceEntities(params.franchiseId, params.recommendationRoundId); },
    refetchOnMount: !params.useCachedData
}); };
export var useGetStorePerformanceEntities = function (params) { return useQuery({
    queryKey: ["storePerformanceEntities"],
    queryFn: function () { return pricePerformanceAPI.getStorePerformanceEntities(params.franchiseId, params.recommendationRoundId, params.entityId); },
    refetchOnMount: !params.useCachedData
}); };
export var useGetProductPerformanceMargins = function (params) { return useQuery({
    queryKey: ["productPerformanceMargins"],
    queryFn: function () { return pricePerformanceAPI.getProductPerformanceMargins(params.franchiseId, params.recommendationRoundId, params.storeId); },
    refetchOnMount: !params.useCachedData
}); };
