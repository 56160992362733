export var viewStoresHeaderRowData = [{
        id: 1,
        columnId: "storeId",
        columnName: "Store ID",
        color: "#f7fbfd",
        visible: true,
        sort: true,
        minWidth: 100
    }, {
        id: 2,
        columnId: "addressLine1",
        columnName: "Street",
        color: "#f7fbfd",
        visible: true,
        minWidth: 100
    }, {
        id: 3,
        columnId: "city",
        columnName: "City",
        color: "#f7fbfd",
        visible: true,
        minWidth: 100
    }, {
        id: 4,
        columnId: "state",
        columnName: "State",
        color: "#f7fbfd",
        visible: true,
        minWidth: 100
    }];
