import { constants } from "@/constants";
export function MappingCardsData(fetchedEntityData) {
    return [{
            id: 1,
            title: constants.price_change_label,
            isRange: false,
            value: fetchedEntityData.estimated_impact.price_change,
            tooltip: constants.price_change_tooltip,
            percentage: true
        }, {
            id: 2,
            title: constants.margin_impact_label,
            isRange: false,
            value: fetchedEntityData.estimated_impact.margin_impact_range,
            dollarSign: false,
            tooltip: constants.margin_impact_tooltip
        }, {
            id: 3,
            title: constants.transaction_impact_label,
            isRange: false,
            value: fetchedEntityData.estimated_impact.transaction_impact_range,
            percentage: false,
            tooltip: constants.transaction_impact_tooltip
        }];
}
export function MappingItemData(fetchedItemData) {
    return fetchedItemData.map(function (item) {
        return {
            category: item.category,
            itemUUID: item.id,
            itemId: item.item_id,
            menuItem: item.item_name,
            currentUnits: item.current_units,
            priceSensitivity: (item === null || item === void 0 ? void 0 : item.price_sensitivity) || "-",
            yourSubmittedPrice: item.submitted_price,
            groupLeader: item.group_leader,
            groupStatusActual: item.group_status_actual,
            groupStatusMessage: item.group_status_message,
            recommendedPrice: item.recommended_price,
            recommendedFlag: item.recommended_flag,
            currentPrice: item.current_price,
            priceChange: item.price_change,
            marginImpactLower: item.margin_impact_lower,
            marginImpactUpper: item.margin_impact_upper,
            marginImpactRange: item.margin_impact_range,
            competitorPriceRange: item.cmpr_range || "-"
        };
    });
}
