var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Accordion, AccordionSummary, AccordionDetails, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
export var PageContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FAFAFA",
    minHeight: "90vh"
});
export var FlexGrowContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1
});
export var Container = styled(Box)({
    padding: "24px",
    margin: "1em",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    borderRadius: "8px"
});
export var PricePerformanceSummaryContainer = styled(Box)({
    padding: "1em",
    backgroundColor: "white"
});
export var RecommendationHeaderContainer = styled(Box)({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 24,
    backgroundColor: "white"
});
export var StyledAccordion = styled(Accordion)({
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
    backgroundColor: "transparent",
    border: "none",
    "&.MuiAccordion-root": {
        padding: 0,
        margin: 0
    },
    "&.MuiAccordion-root:before": {
        display: "none"
    },
    marginBottom: "24px"
});
export var StyledAccordionSummary = styled(AccordionSummary)({
    padding: 0,
    "&.MuiAccordionSummary-root": {
        minHeight: "auto"
    },
    "&.MuiAccordionSummary-content": {
        margin: 0
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
        marginLeft: "8px"
    }
});
export var StyledAccordionDetails = styled(AccordionDetails)({
    display: "flex",
    flexDirection: "row",
    gap: "24px",
    padding: 0,
    justifyContent: "center",
    ".MuiAccordionDetails-root": {
        padding: 0
    },
    marginBottom: "10px"
});
export var PerformanceTableStyle = {
    stickyHeaderOffset: 24,
    groupedHeader: {
        borderBottom: "none",
        backgroundColor: "#F3EFFB",
        maxHeight: "24px",
        padding: "1px 16px",
        border: "0x"
    },
    header: {
        maxHeight: "40px",
        padding: "4px 16px",
        borderBottom: "none"
    },
    body: {
        maxHeight: "42px",
        padding: "8px 16px",
        borderBottom: "none"
    }
};
export var marginTableStyle = __assign(__assign({}, PerformanceTableStyle), { groupedHeader: __assign(__assign({}, PerformanceTableStyle.groupedHeader), { ":not(:last-child)": {
            backgroundColor: "white"
        } }), header: __assign(__assign({}, PerformanceTableStyle.header), { backgroundColor: "#F3EFFB" }) });
export var TitleContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
});
