// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tier-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 3.5em;
  margin-top: 1em;
  align-items: center;
}

.tier-row-details {
  display: flex;
  justify-content: flex-start;
  margin-top: 1em;
  margin-bottom: 1.5rem;
  align-items: center;
  gap: 1em;
}

.tier-row-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.5em;
  height: 41.99px;
  align-items: center;
}

.tier-row-submit {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.5em;
  height: fit-content;
  align-items: flex-end;
}

.breadcrumb-link {
  color: rgba(102, 102, 102, 1);
  font-weight: 400;
  text-decoration: none;
}

.breadcrumb-deeper-link {
  color: rgba(33, 33, 33, 1);
  font-weight: 600;
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/views/Home/Title Section/TitleSection.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,aAAa;EACb,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,eAAe;EACf,qBAAqB;EACrB,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,UAAU;EACV,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,UAAU;EACV,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,6BAA6B;EAC7B,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;EAC1B,gBAAgB;EAChB,qBAAqB;AACvB","sourcesContent":[".tier-row {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  height: 3.5em;\n  margin-top: 1em;\n  align-items: center;\n}\n\n.tier-row-details {\n  display: flex;\n  justify-content: flex-start;\n  margin-top: 1em;\n  margin-bottom: 1.5rem;\n  align-items: center;\n  gap: 1em;\n}\n\n.tier-row-info {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  gap: 0.5em;\n  height: 41.99px;\n  align-items: center;\n}\n\n.tier-row-submit {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  gap: 0.5em;\n  height: fit-content;\n  align-items: flex-end;\n}\n\n.breadcrumb-link {\n  color: rgba(102, 102, 102, 1);\n  font-weight: 400;\n  text-decoration: none;\n}\n\n.breadcrumb-deeper-link {\n  color: rgba(33, 33, 33, 1);\n  font-weight: 600;\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
