var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
export var ModalContainer = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 800px;\n  height: 640px;\n  background: white;\n  border-radius: 8px;\n  box-shadow: 24px;\n"], ["\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 800px;\n  height: 640px;\n  background: white;\n  border-radius: 8px;\n  box-shadow: 24px;\n"])));
export var Title = styled(Box)({
    display: "flex",
    alignItems: "center",
    height: 64
});
export var HeaderContent = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: 4,
    height: 48
});
export var Body = styled(Box)({
    display: "flex",
    flexDirection: "column",
    padding: "24px 24px 0px 24px",
    overflowY: "scroll",
    gap: 12,
    height: 500,
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
        display: "none"
    }
});
export var Footer = styled(Box)({
    display: "flex",
    padding: "12px 24px",
    justifyContent: "flex-end",
    gap: 12
});
export var SearchContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    height: "44px",
    width: "280px",
    padding: "12px",
    border: "1px solid #ccc",
    borderRadius: "4px"
});
var templateObject_1;
