var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31;
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { Property } from "@/utilities/decorators/property";
export var Item = (_class = /** @class */ (function () {
    function Item() {
        var _this = this;
        this.id = (function () { return _initializerWarningHelper(_descriptor, _this); })(); // uuid
        this.category = (function () { return _initializerWarningHelper(_descriptor2, _this); })();
        this.itemId = (function () { return _initializerWarningHelper(_descriptor3, _this); })();
        this.itemName = (function () { return _initializerWarningHelper(_descriptor4, _this); })();
        this.currentUnits = (function () { return _initializerWarningHelper(_descriptor5, _this); })();
        this.priceSensitivity = (function () { return _initializerWarningHelper(_descriptor6, _this); })();
        this.submittedPrice = (function () { return _initializerWarningHelper(_descriptor7, _this); })();
        this.groupLeader = (function () { return _initializerWarningHelper(_descriptor8, _this); })();
        this.recommendedPrice = (function () { return _initializerWarningHelper(_descriptor9, _this); })();
        this.currentPrice = (function () { return _initializerWarningHelper(_descriptor10, _this); })();
        this.priceChange = (function () { return _initializerWarningHelper(_descriptor11, _this); })();
        this.marginImpactLower = (function () { return _initializerWarningHelper(_descriptor12, _this); })();
        this.marginImpactUpper = (function () { return _initializerWarningHelper(_descriptor13, _this); })();
        this.marginImpactRange = (function () { return _initializerWarningHelper(_descriptor14, _this); })();
        this.entityId = (function () { return _initializerWarningHelper(_descriptor15, _this); })();
        this.entityRecommendationId = (function () { return _initializerWarningHelper(_descriptor16, _this); })();
        this.cmprRange = (function () { return _initializerWarningHelper(_descriptor17, _this); })();
        this.costPerUnit = (function () { return _initializerWarningHelper(_descriptor18, _this); })();
        this.updatedUnits = (function () { return _initializerWarningHelper(_descriptor19, _this); })();
        this.currentMargin = (function () { return _initializerWarningHelper(_descriptor20, _this); })();
        this.updatedMargin = (function () { return _initializerWarningHelper(_descriptor21, _this); })();
        this.minPriceThreshold = (function () { return _initializerWarningHelper(_descriptor22, _this); })();
        this.maxPriceThreshold = (function () { return _initializerWarningHelper(_descriptor23, _this); })();
        this.groupStatusActual = (function () { return _initializerWarningHelper(_descriptor24, _this); })();
        this.groupStatusMessage = (function () { return _initializerWarningHelper(_descriptor25, _this); })();
        this.recommendedFlag = (function () { return _initializerWarningHelper(_descriptor26, _this); })();
        this.modifiedFlag = (function () { return _initializerWarningHelper(_descriptor27, _this); })();
        this.createdBy = (function () { return _initializerWarningHelper(_descriptor28, _this); })();
        this.createdDate = (function () { return _initializerWarningHelper(_descriptor29, _this); })();
        this.modifiedBy = (function () { return _initializerWarningHelper(_descriptor30, _this); })();
        this.modifiedDate = (function () { return _initializerWarningHelper(_descriptor31, _this); })();
    }
    return Item;
}()), _descriptor = _applyDecoratedDescriptor(_class.prototype, "id", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "category", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "itemId", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "itemName", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentUnits", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "priceSensitivity", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "submittedPrice", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "groupLeader", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "recommendedPrice", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "currentPrice", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "priceChange", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "marginImpactLower", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "marginImpactUpper", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "marginImpactRange", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "entityId", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "entityRecommendationId", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "cmprRange", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "costPerUnit", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "updatedUnits", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "currentMargin", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "updatedMargin", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "minPriceThreshold", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "maxPriceThreshold", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "groupStatusActual", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "groupStatusMessage", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "recommendedFlag", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "modifiedFlag", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "createdBy", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "createdDate", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "modifiedBy", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "modifiedDate", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _class);
