export var selectedTier = "01";
export var selectedRecommendation = "Q2 2024";
export var mappingBreadCrumbData = function (recId, rec) {
    breadCrumbData[1] = {
        id: 2,
        label: rec !== null && rec !== void 0 ? rec : "",
        link: "/recommendation?recId=".concat(recId)
    };
    return breadCrumbData;
};
export var breadCrumbData = [{
        id: 1,
        label: "Price Recommendation",
        link: "/pricing-rounds"
    }];
