export var storeTierHeaderData = [{
        id: 1,
        columnId: "storeId",
        columnName: "Store ID",
        color: "#FCFBFE",
        dollarSign: false,
        visible: true,
        minWidth: 200
    }, {
        id: 2,
        columnId: "entityId",
        columnName: "Tier",
        color: "#FCFBFE",
        visible: true,
        dollarSign: false,
        customValue: "customTierName",
        minWidth: 200
    }, {
        id: 3,
        columnId: "addressLine1",
        columnName: "Street",
        color: "#FCFBFE",
        visible: true,
        minWidth: 500
    }, {
        id: 4,
        columnId: "city",
        columnName: "City",
        color: "#FCFBFE",
        visible: true,
        dollarSign: false,
        minWidth: 200
    }, {
        id: 5,
        columnId: "state",
        columnName: "State",
        color: "#FCFBFE",
        dollarSign: false,
        visible: true,
        minWidth: 100
    }];
