import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
export var Container = styled(Box)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "right",
    width: "100%",
    padding: "1em 1em 0 1em",
    gap: "1em",
    marginBottom: 0
});
export var IconContainer = styled(Box)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
});
