var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { Property } from "@/utilities/decorators/property";
export var User = (_class = /** @class */ (function () {
    function User() {
        var _this = this;
        this.id = (function () { return _initializerWarningHelper(_descriptor, _this); })(); //uuid
        this.ownerOperatorId = (function () { return _initializerWarningHelper(_descriptor2, _this); })();
        this.firstName = (function () { return _initializerWarningHelper(_descriptor3, _this); })();
        this.lastName = (function () { return _initializerWarningHelper(_descriptor4, _this); })();
        this.userRoleId = (function () { return _initializerWarningHelper(_descriptor5, _this); })();
        this.onboardedFlag = (function () { return _initializerWarningHelper(_descriptor6, _this); })();
        this.active = (function () { return _initializerWarningHelper(_descriptor7, _this); })();
    }
    return User;
}()), _descriptor = _applyDecoratedDescriptor(_class.prototype, "id", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ownerOperatorId", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "firstName", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "lastName", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "userRoleId", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "onboardedFlag", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "active", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _class);
