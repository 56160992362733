export var selectedTier = "01";
export var selectedRecommendation = "Q2 2024";
export var mappingBreadCrumbData = function (rec, recId) {
    breadCrumbData[1] = {
        id: 2,
        label: rec,
        link: "/recommendation?recId=".concat(recId)
    };
    return breadCrumbData;
};
export var breadCrumbData = [{
        id: 1,
        label: "Price Recommendation",
        link: "/franchisee"
    }];
