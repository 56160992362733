export var autoCompleteStyles = {
    minWidth: "400px"
};
export var dropdownInputLabelStyles = function (hasSelectedOption) { return ({
    top: hasSelectedOption ? "0px" : "-8px"
}); };
export var dropdownStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "16px",
    width: "180px"
};
