import pino from "pino";
/**
 * Logger const for project
 * import to any file to use the logger
 */
var logger = pino({
    transport: {
        target: "pino-pretty",
        levels: process.env.PINO_LOG_LEVEL || "info",
        options: {
            colorize: true
        }
    }
});
export default logger;
