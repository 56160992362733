export var baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "#BFBFBF",
    borderStyle: "dashed",
    outline: "none",
    transition: "border .24s ease-in-out",
    margin: "24px",
    height: "152px",
    cursor: "grab"
};
export var dragActiveStyles = {
    backgroundColor: "#e6f1f780"
};
export var focusStyles = {
    backgroundColor: "#e6f1f780"
};
export var dragRejectStyles = {
    backgroundColor: "#FF696120",
    cursor: "not-allowed"
};
