var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Box, Paper, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
export var CheckBoxContainer = styled(Box)({
    display: "flex",
    height: "100%",
    overflowY: "auto",
    borderBottom: "1px solid #D9D9D9",
    scrollbarGutter: "none",
    flexDirection: "column"
});
export var Label = styled(Box)({
    fontWeight: 400,
    fontSize: 12,
    letterSpacing: 0.15
});
export var SmallLabel = styled(Box)({
    fontSize: 10,
    lineHeight: "9.5px"
});
export var FilterPopperPaper = styled(Paper)(function (_ref) {
    var theme = _ref.theme;
    return {
        display: "flex",
        flexDirection: "column",
        width: 333,
        height: 444,
        padding: theme.spacing(2),
        boxShadow: theme.shadows[3],
        gap: theme.spacing(2)
    };
});
export var ButtonBar = styled(Box)({
    display: "flex",
    border: "1px solid #E2CEF8",
    borderRadius: "8px",
    padding: "4px",
    justifyContent: "space-between",
    gap: "5px",
    height: 38
});
var getSelectedButtonStyles = function (selected) { return ({
    backgroundColor: selected ? "#46137F" : "#fff",
    color: selected ? "#fff" : "#46137F",
    border: "1px solid #46137F"
}); };
export var StyledSortIcon = styled("img", {
    shouldForwardProp: function (prop) { return prop !== "isSelected" && prop !== "rotate"; }
})(function (_ref2) {
    var isSelected = _ref2.isSelected, rotate = _ref2.rotate;
    return {
        height: 20,
        marginRight: 3,
        marginLeft: 3,
        transform: rotate ? "rotate(180deg)" : "none",
        filter: isSelected ? "invert(1) grayscale(1) brightness(2)" : "none"
    };
});
export var StyledToggleButton = styled(Button, {
    shouldForwardProp: function (prop) { return prop !== "selected"; }
})(function (_ref3) {
    var selected = _ref3.selected;
    return __assign(__assign({ display: "flex", flexDirection: "row", justifyContent: "start", fontSize: 10, width: "100%", borderRadius: "6px", fontWeight: "600", alignItems: "center", textTransform: "none", lineHeight: "15.2px", letterSpacing: "0px", padding: "4px" }, getSelectedButtonStyles(selected)), { "&:hover": __assign({}, getSelectedButtonStyles(selected)), "&.MuiButtonBase-root:hover": {
            bgcolor: "transparent"
        } });
});
