export var toolbarStyles = function (open) { return ({
    display: "flex",
    alignItems: "center",
    justifyContent: open ? "space-between" : "center",
    position: "relative"
}); };
export var iconButtonStyles = function (open) { return ({
    position: open ? "absolute" : "static",
    right: open ? 0 : "auto",
    width: 54,
    height: 54,
    backgroundColor: "transparent",
    "&:hover": {
        backgroundColor: "transparent"
    }
}); };
export var iconStyles = {
    width: 24,
    height: 24
};
