var _a, _b;
import { ORDER_BY, SORT_BY } from "@/apis/types";
export var sortByLabelMap = (_a = {},
    _a[SORT_BY.ITEM_ID] = "Item ID",
    _a[SORT_BY.ITEM_NAME] = "Item Name",
    _a[SORT_BY.CATEGORY] = "Category",
    _a[SORT_BY.RECOMMENDED_PRICE] = "Recommended Price",
    _a[SORT_BY.CURRENT_PRICE] = "Current Price",
    _a[SORT_BY.MARGIN_IMPACT_RANGE] = "Margin Impact Range",
    _a[SORT_BY.SUBMITTED_PRICE] = "Price to Submit",
    _a[SORT_BY.PRICE_CHANGE] = "Price Change",
    _a[SORT_BY.GROUP_LEADER] = "Group Leader",
    _a[SORT_BY.WEEKLY_UNITS] = "Weekly Units",
    _a);
export var orderByLabelMap = (_b = {},
    _b[ORDER_BY.ASC] = "ASC",
    _b[ORDER_BY.DESC] = "DESC",
    _b);
