import { isNumber } from "lodash";
import { formatLongString } from "@/components/DataTable/utilities";
import { constants } from "@/constants";
import { editionStateColors } from "@/views/Main Context/utils";
export function MappingCardsData(object) {
    return [{
            id: 1,
            title: constants.price_change_label,
            isRange: false,
            value: object.estimatedImpact.priceChange,
            tooltip: constants.price_change_tooltip,
            percentage: true
        }, {
            id: 2,
            title: constants.margin_impact_label,
            isRange: false,
            value: object.estimatedImpact.marginImpactRange,
            dollarSign: false,
            tooltip: constants.margin_impact_tooltip
        }, {
            id: 3,
            title: constants.transaction_impact_label,
            isRange: false,
            value: object.estimatedImpact.transactionImpactRange,
            percentage: false,
            tooltip: constants.transaction_impact_tooltip
        }];
}
export var isLargePriceChange = function (_ref) {
    var newPrice = _ref.newPrice, oldPrice = _ref.oldPrice, recommendedPrice = _ref.recommendedPrice, recommendedFlag = _ref.recommendedFlag;
    if (recommendedFlag && recommendedPrice) {
        return newPrice > recommendedPrice && Math.abs((newPrice - oldPrice) / oldPrice) >= 0.25;
    }
    return Math.abs((newPrice - oldPrice) / oldPrice) >= 0.25;
};
export var getBorderColor = function (editingState, isActive) {
    return isActive ? editionStateColors[editingState] : "#D9D9D9";
};
export var getCellValue = function (_ref2) {
    var cellKey = _ref2.cellKey, cellValue = _ref2.cellValue, hasDollarSign = _ref2.hasDollarSign;
    if (cellKey === "itemName" && typeof cellValue === "string") {
        return formatLongString(cellKey, cellValue);
    }
    if (cellKey === "currentUnits" && isNumber(cellValue)) {
        return cellValue.toLocaleString();
    }
    if (cellKey === "priceChange") {
        if (isNumber(cellValue) && cellValue < 0) {
            return "$(".concat(Math.abs(cellValue).toFixed(2), ")");
        }
    }
    return hasDollarSign && isNumber(cellValue) ? "$".concat(cellValue.toFixed(2)) : cellValue;
};
