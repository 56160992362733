// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comp-tables {
  width: 1330px;
  overflow-x: visible;
}
.comp-tables-container {
  height: 500px;
  overflow-y: auto;
  .MuiTableContainer-root {
    overflow-x: unset !important;
  }
}

.comp-tables::-webkit-scrollbar,
.comp-tables-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.comp-tables::-webkit-scrollbar-track,
.comp-tables-container::-webkit-scrollbar-track {
  background: transparent;
}

.comp-tables::-webkit-scrollbar-thumb,
.comp-tables-container::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/views/Home/CompetitorPrice/ComparisonTable/ComparisonTable.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,gBAAgB;EAChB;IACE,4BAA4B;EAC9B;AACF;;AAEA;;EAEE,UAAU;EACV,WAAW;AACb;;AAEA;;EAEE,uBAAuB;AACzB;;AAEA;;EAEE,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[".comp-tables {\n  width: 1330px;\n  overflow-x: visible;\n}\n.comp-tables-container {\n  height: 500px;\n  overflow-y: auto;\n  .MuiTableContainer-root {\n    overflow-x: unset !important;\n  }\n}\n\n.comp-tables::-webkit-scrollbar,\n.comp-tables-container::-webkit-scrollbar {\n  width: 8px;\n  height: 8px;\n}\n\n.comp-tables::-webkit-scrollbar-track,\n.comp-tables-container::-webkit-scrollbar-track {\n  background: transparent;\n}\n\n.comp-tables::-webkit-scrollbar-thumb,\n.comp-tables-container::-webkit-scrollbar-thumb {\n  background: #d9d9d9;\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
