import { ErrorType } from "@/views/StoreTier/EditableCell";
export var validateInput = function (newValue, currentTierId, rows) {
    var correctedValue = newValue.replace(/\s+/g, " ");
    var maxLength = 60;
    if (correctedValue.length > maxLength) {
        return {
            type: ErrorType.MAX_LENGTH,
            message: null
        };
    }
    if (correctedValue.includes(",")) {
        return {
            type: ErrorType.NO_COMMAS,
            message: "No commas allowed"
        };
    }
    // Allow only letters, numbers, spaces, dashes (-) and colons (:)
    if (/[^a-zA-Z0-9\s\-:]/.test(correctedValue)) {
        return {
            type: ErrorType.INVALID_CHARACTERS,
            message: "Special Characters Not Allowed"
        };
    }
    // Check if name consists solely of a dash or colon
    if (correctedValue.trim() === "-" || correctedValue.trim() === ":") {
        return {
            type: ErrorType.INVALID_CHARACTERS,
            message: "Name cannot consist of only a dash or colon"
        };
    }
    var isDuplicate = rows.some(function (row) { var _a; return row.tierId !== currentTierId && ((_a = row.customTierName) === null || _a === void 0 ? void 0 : _a.trim()) === correctedValue.trim() && correctedValue.trim() !== ""; });
    return isDuplicate ? {
        type: ErrorType.DUPLICATE_NAME,
        message: "Name is in use"
    } : {
        type: null,
        message: null
    };
};
