// shared button styles
export var buttonStyles = {
    minWidth: "max-content",
    cursor: "pointer",
    display: "flex",
    height: "44px",
    padding: "8px 16px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    border: "1px solid #46137f",
    color: "#46137F",
    ":disabled": {
        color: "#9E9E9E",
        border: "1px solid #d9d9d9"
    }
};
export var buttonText = {
    fontSize: "14px",
    fontWeight: 600,
    letterSpacing: "0.6px",
    textTransform: "uppercase"
};
