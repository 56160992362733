import { createTheme } from "@mui/material";
export var theme = createTheme({
    typography: {
        fontFamily: "'Roboto', 'Open Sans', sans-serif",
        h1: {},
        h2: {},
        h3: {
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "800",
            fontSize: "24px",
            lineHeight: "32.68px",
            letterSpacing: "0.8px"
        },
        h4: {
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "400",
            fontSize: "34px",
            lineHeight: "41.99px",
            letterSpacing: ".25px",
            color: "rgba(0, 0, 0, 0.87)"
        },
        h5: {
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "400",
            fontSize: "24px",
            lineHeight: "32.02px"
        },
        h6: {
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "400",
            fontSize: "20px",
            lineHeight: "30px",
            letterSpacing: ".15px"
        },
        body1: {
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: ".15px"
        },
        subtitle1: {
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "28px",
            letterSpacing: ".15px"
        },
        subtitle2: {
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: ".17px"
        },
        button: {
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "600",
            fontSize: "13px",
            lineHeight: "22px",
            letterSpacing: ".46px"
        },
        body2: {
            fontFamily: "'Open Sans', sans-serif",
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "20px",
            letterSpacing: "0.17000000178813934px"
        },
        caption: {
            fontSize: "13px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "600",
            lineHeight: "19.76px"
        }
    },
    palette: {
        primary: {
            main: "#0E336A",
            light: "#000000"
        },
        secondary: {
            main: "rgba(102,102,102,1)",
            light: "rgba(0, 0, 0, 0.6)"
        },
        background: {
            default: "#F5F5F5"
        },
        info: {
            main: "#1976D24D"
        },
        success: {
            main: "#207F19"
        },
        error: {
            main: "#D0241E"
        }
    },
    components: {
        // Name of the component
        MuiTab: {
            styleOverrides: {
            // Name of the slot
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    height: "40px"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    contained: {
                        background: "#004096"
                    },
                    variants: [{
                            props: {
                                variant: "brandOutlined"
                            },
                            style: {
                                border: "1px solid #46137F",
                                // TODO: update to brandColors.clientColor
                                color: "#46137F",
                                ":hover": {
                                    backgroundColor: "#46137F",
                                    // TODO: update to brandColors.clientColor
                                    color: "white",
                                    "& img": {
                                        filter: "brightness(0) invert(1)"
                                    }
                                },
                                ":active": {
                                    backgroundColor: "#9249E4",
                                    //TODO: update to brandColors.hoverColor
                                    color: "white",
                                    "& img": {
                                        filter: "brightness(0) invert(1)"
                                    }
                                }
                            }
                        }]
                }
            }
        },
        MuiListSubheader: {
            styleOverrides: {
                root: {
                    color: "#000000"
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: "#0E336A",
                    color: "#fff",
                    // set the font color of the tooltip
                    fontFamily: "'Open Sans', sans-serif",
                    // set the font family of the tooltip
                    fontSize: "10px",
                    // set the font size of the tooltip
                    fontWeight: "600",
                    lineHeight: "14px"
                }
            }
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                // Map the new variant to render a <h1> by default
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: "1em",
                    marginLeft: "0.25em",
                    color: "rgba(9,115,167,1)"
                }
            }
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    "&:active": {
                        color: "rgba(9,115,167,1)"
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#3597C1"
                    }
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    "&:hover .MuiTouchRipple-root": {
                        borderRadius: "0px",
                        zIndex: "-1000",
                        backgroundColor: "#ECE0FA"
                    }
                }
            },
            defaultProps: {
                disableRipple: true
            }
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1280,
            lg: 1700,
            xl: 1920
        }
    }
});
