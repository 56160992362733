import { isNumber } from "lodash";
import { formatLongString } from "@/components/DataTable/utilities";
import { constants } from "@/constants";
import { editionStateColors } from "@/views/Main Context/utils";
export var itemHeaderData = [{
        id: 1,
        columnId: "category",
        columnName: "Category",
        color: "rgba(53, 151, 193, 0.04)",
        dollarSign: false,
        visible: true,
        sort: false,
        minWidth: 2,
        width: 90
    }, {
        id: 2,
        columnId: "itemId",
        columnName: "Item ID",
        color: "rgba(53, 151, 193, 0.04)",
        visible: true,
        dollarSign: false,
        sort: false,
        minWidth: 2,
        width: 70
    }, {
        id: 3,
        columnId: "menuItem",
        columnName: "Menu Item",
        color: "rgba(53, 151, 193, 0.04)",
        visible: true,
        dollarSign: false,
        sort: false,
        minWidth: 2,
        width: 120
    }, {
        id: 4,
        columnId: "currentUnits",
        columnName: "Weekly Units",
        color: "rgba(53, 151, 193, 0.04)",
        visible: true,
        dollarSign: false,
        sort: false,
        minWidth: 130
    }, {
        id: 5,
        columnId: "priceSensitivity",
        columnName: "Price Sensitivity",
        color: "rgba(53, 151, 193, 0.04)",
        visible: true,
        dollarSign: false,
        sort: false,
        minWidth: 2,
        width: 100
    }, {
        id: 6,
        columnId: "yourSubmittedPrice",
        columnName: "Price to Submit",
        color: "rgba(243, 248, 235, 1)",
        visible: true,
        dollarSign: true,
        sort: false,
        isEditable: true,
        minWidth: 200
    }, {
        id: 7,
        columnId: "groupLeader",
        columnName: "Group Leader",
        tooltip: constants.group_leader_tooltip,
        color: "rgba(53, 151, 193, 0.04)",
        visible: true,
        dollarSign: false,
        sort: false,
        width: 120,
        align: "center"
    }, {
        id: 8,
        columnId: "recommendedPrice",
        columnName: "Recommended Price",
        color: "rgba(53, 151, 193, 0.04)",
        visible: true,
        dollarSign: true,
        sort: true,
        minWidth: 140
    }, {
        id: 9,
        columnId: "currentPrice",
        columnName: "Current Price",
        color: "rgba(53, 151, 193, 0.04)",
        visible: true,
        dollarSign: true,
        sort: false,
        minWidth: 2,
        width: 120
    }, {
        id: 10,
        columnId: "priceChange",
        columnName: "Price Change",
        color: "rgba(53, 151, 193, 0.04)",
        visible: true,
        dollarSign: true,
        sort: false,
        minWidth: 130
    }, {
        id: 11,
        columnId: "marginImpactLower",
        columnName: "Margin Impact Lower",
        color: "rgba(53, 151, 193, 0.04)",
        visible: false,
        dollarSign: true,
        sort: false
    }, {
        id: 12,
        columnId: "marginImpactUpper",
        columnName: "Margin Impact Upper",
        color: "rgba(53, 151, 193, 0.04)",
        visible: false,
        dollarSign: true,
        sort: false,
        minWidth: 2,
        width: 160
    }, {
        id: 13,
        columnId: "marginImpactRange",
        columnName: "Margin Impact",
        color: "rgba(53, 151, 193, 0.04)",
        visible: true,
        dollarSign: true,
        sort: false,
        minWidth: 2,
        width: 160
    }, {
        id: 14,
        columnId: "competitorPriceLower",
        columnName: "Competitor Price Lower",
        color: "rgba(53, 151, 193, 0.04)",
        visible: false,
        dollarSign: true,
        sort: false,
        minWidth: 2,
        width: 160
    }, {
        id: 15,
        columnId: "competitorPriceUpper",
        columnName: "Competitor Price Upper",
        color: "rgba(53, 151, 193, 0.04)",
        visible: false,
        dollarSign: true,
        sort: false,
        minWidth: 2,
        width: 160
    }, {
        id: 16,
        columnId: "competitorPriceRange",
        columnName: "Competitor Price Range",
        color: "rgba(53, 151, 193, 0.04)",
        visible: false,
        dollarSign: false,
        sort: false,
        minWidth: 2,
        width: 180
    }];
export var getBorderColor = function (editingState, isActive) {
    return isActive ? editionStateColors[editingState] : "#D9D9D9";
};
export var getCellValue = function (_ref) {
    var cellKey = _ref.cellKey, cellValue = _ref.cellValue, hasDollarSign = _ref.hasDollarSign;
    if (cellKey === "menuItem" && typeof cellValue === "string") {
        return formatLongString(cellKey, cellValue);
    }
    if (cellKey === "currentUnits" && isNumber(cellValue)) {
        return cellValue.toLocaleString();
    }
    if (cellKey === "priceChange") {
        if (isNumber(cellValue) && cellValue < 0) {
            return "$(".concat(Math.abs(cellValue).toFixed(2), ")");
        }
    }
    return hasDollarSign && isNumber(cellValue) ? "$".concat(cellValue.toFixed(2)) : cellValue;
};
