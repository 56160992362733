import { saveAs } from "file-saver";
import _ from "lodash";
export var jsonToCsv = function (json) {
    var jsonArray = Array.isArray(json) ? json : [json];
    var headers = Object.keys(jsonArray[0]);
    var csvRows = [headers.join(",")];
    jsonArray.map(function (row) { return csvRows.push(Object.values(row).join(",")); });
    return csvRows.join("\n");
};
export var download = function (data, fileName, fileType) {
    var blob = new Blob([data], {
        type: fileType
    });
    saveAs(blob, fileName);
};
export var toCapitalizedSnakeCase = function (text) {
    return _.startCase(_.toLower(text)).replace(/\s+/g, "_");
};
