export var modalContainer = {
    position: "fixed",
    bottom: 145,
    right: 105,
    display: "flex"
};
export var modalRectangle = {
    height: 153,
    width: 500,
    backgroundColor: "#FFFFFF",
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 16px",
    borderRadius: "8px",
    boxShadow: "4px 4px 3px 0px #00000040"
};
export var modalLogo = {
    height: "92px",
    width: "92px",
    marginRight: "16px",
    borderRadius: "50%",
    overflow: "hidden",
    backgroundColor: "#ebebeb",
    color: "#595959",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};
export var unavailableLogoContainer = {
    height: "100px",
    width: "100px",
    marginRight: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};
export var priceAdvisorContent = {
    gap: "4px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
};
export var triangle = {
    width: 0,
    height: 0,
    borderTop: "6px solid transparent",
    borderBottom: "6px solid transparent",
    borderLeft: "12px solid white",
    filter: "drop-shadow(4px 4px 3px  #00000040",
    marginTop: 25,
    zIndex: 1
};
export var modalCloseButton = {
    padding: 0,
    height: 24,
    width: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    ":hover": {
        cursor: "pointer",
        backgroundColor: "white"
    }
};
export var modalCloseButtonImg = {
    height: "100%",
    backgroundColor: "white",
    borderColor: "white"
};
