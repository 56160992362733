import { snakeCase } from "lodash";
export var generateGetItemsAndCategoriesUrl = function (entityRecommendationId, params) {
    var url = "recommendation/items?entity_recommendation_id=".concat(entityRecommendationId);
    Object.entries(params).forEach(function (_ref) {
        var key = _ref[0], value = _ref[1];
        if (key === "categories") {
            url += "&".concat(key, "=").concat(value === null || value === void 0 ? void 0 : value.join("|"));
        }
        else if (value) {
            url += "&".concat(snakeCase(key), "=").concat(value);
        }
    });
    return url;
};
export var generateResetDefaultPricesUrl = function (entityRecommendationId, params) {
    var filterKeys = ["statusFlag"];
    var url = "recommendation/items/reset?entity_recommendation_id=".concat(entityRecommendationId);
    filterKeys.forEach(function (key) {
        var value = params[key];
        if (value) {
            url += "&".concat(snakeCase(key), "=").concat(value);
        }
    });
    return url;
};
