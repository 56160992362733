var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { Property } from "@/utilities/decorators/property";
export var Store = (_class = /** @class */ (function () {
    function Store() {
        var _this = this;
        this.id = (function () { return _initializerWarningHelper(_descriptor, _this); })();
        this.storeId = (function () { return _initializerWarningHelper(_descriptor2, _this); })();
        this.cmprStoreId = (function () { return _initializerWarningHelper(_descriptor3, _this); })();
        this.cmprStoreName = (function () { return _initializerWarningHelper(_descriptor4, _this); })();
        this.cmprStoreAddress = (function () { return _initializerWarningHelper(_descriptor5, _this); })();
        this.cmprStoreLongitude = (function () { return _initializerWarningHelper(_descriptor6, _this); })();
        this.cmprStoreLatitude = (function () { return _initializerWarningHelper(_descriptor7, _this); })();
        this.extractDate = (function () { return _initializerWarningHelper(_descriptor8, _this); })();
        this.cmprStoreDstcMiles = (function () { return _initializerWarningHelper(_descriptor9, _this); })();
        this.cmprPriceIndex = (function () { return _initializerWarningHelper(_descriptor10, _this); })();
        this.cmprStoreAddressSummary = (function () { return _initializerWarningHelper(_descriptor11, _this); })();
        this.cmprCategory = (function () { return _initializerWarningHelper(_descriptor12, _this); })();
        this.cmprColor = (function () { return _initializerWarningHelper(_descriptor13, _this); })();
    }
    return Store;
}()), _descriptor = _applyDecoratedDescriptor(_class.prototype, "id", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "storeId", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "cmprStoreId", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "cmprStoreName", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "cmprStoreAddress", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "cmprStoreLongitude", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "cmprStoreLatitude", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "extractDate", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "cmprStoreDstcMiles", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "cmprPriceIndex", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "cmprStoreAddressSummary", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "cmprCategory", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "cmprColor", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _class);
