var parseCurrency = function (value) { return parseFloat(value.replace(/[^0-9.-]+/g, "")); };
var safeParseCurrency = function (value) {
    if (typeof value === "string") {
        var parsedValue = parseCurrency(value);
        var validatedValue = isNaN(parsedValue) ? 0 : parsedValue;
        return value.startsWith("$(") && value.endsWith(")") ? validatedValue * -1 : validatedValue;
    }
    return 0;
};
var getChartRange = function (achieved, estimatedMin, estimatedMax) {
    var values = [achieved, estimatedMin, estimatedMax];
    var min = Math.min.apply(Math, values);
    var max = Math.max.apply(Math, values);
    var range = max - min;
    // 5% padding
    var padding = range * 5 / 100;
    return {
        min: Math.floor((min - padding) * 100) / 100,
        max: Math.ceil((max + padding) * 100) / 100
    };
};
export var getCurrencyGaugeChartData = function (data) {
    var achievedValue = safeParseCurrency(data.totalCurrency.toString());
    var estimatedMax = safeParseCurrency(data.estimatedTotalCurrency.toString());
    var estimatedMin = safeParseCurrency(data.estimatedDirectCurrencyLow.toString());
    var estimatedHigh = safeParseCurrency(data.estimatedDirectCurrencyHigh.toString());
    return {
        min: 0,
        max: estimatedHigh,
        estimatedMin: estimatedMin,
        estimatedMax: estimatedMax,
        achievedValue: achievedValue,
        estimateAchieved: achievedValue >= estimatedMax || achievedValue >= estimatedMin && achievedValue <= estimatedHigh || !!data.estimateAchieved,
        isCurrency: true
    };
};
export var getTransactionGaugeChartData = function (data) {
    var _a = getChartRange(data.transCountPercentage, data.transCountPercentageLow, data.transCountPercentageHigh), min = _a.min, max = _a.max;
    return {
        min: min,
        max: max,
        estimatedMin: data.transCountPercentageLow,
        estimatedMax: data.transCountPercentageHigh,
        achievedValue: data.transCountPercentage,
        estimateAchieved: data.estimateAchieved,
        isCurrency: false
    };
};
export var getDonutConfig = function (greyBeforeSegment, blueSegment, greyAfterSegment) {
    return {
        datasets: [{
                data: [greyBeforeSegment, blueSegment, greyAfterSegment],
                backgroundColor: ["#F5F5F5", "#056FAD99", "#F5F5F5"],
                hoverBackgroundColor: ["#F5F5F5", "#056FAD99", "#F5F5F5"],
                borderWidth: 0,
                cutout: "50%",
                circumference: 180,
                rotation: 270
            }]
    };
};
