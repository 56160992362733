var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable max-len */
import { api } from "../configs/axiosConfig";
import logger from "@/logger";
import { ModelMapper } from "@/mappers/ModelMapper";
import { ProductMargin } from "@/types/pricePerformance/ProductMargin";
import { RecommendationSummary } from "@/types/pricePerformance/RecommendationSummary";
import { StoreMargin } from "@/types/pricePerformance/StoreMargin";
import { StoreSales } from "@/types/pricePerformance/StoreSales";
import { StoreTransactionCount } from "@/types/pricePerformance/StoreTransactionCount";
export var pricePerformanceAPI = {
    getRecommendationSummary: function (franchiseId, recommendationRoundId) { return __awaiter(void 0, void 0, void 0, function () {
        var url, response, RecSummaryKeyMapping, mappedRecommendationSummary;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!recommendationRoundId || recommendationRoundId.trim() === "") {
                        logger.error("Empty recommendation round ID provided to getRecommendationSummary");
                        throw new Error("Recommendation round ID is required");
                    }
                    url = "/price-performance/recommendations?franchise_id=".concat(franchiseId, "&recommendation_round_id=").concat(recommendationRoundId);
                    return [4 /*yield*/, api.request({
                            url: url,
                            method: "GET"
                        })];
                case 1:
                    response = _a.sent();
                    if (!response.data || !response.data.data) {
                        logger.error("Error recommendation summary data from API");
                        return [2 /*return*/, {}];
                    }
                    RecSummaryKeyMapping = ModelMapper.toModel(response.data.data, RecommendationSummary);
                    mappedRecommendationSummary = {
                        storeSales: ModelMapper.toModel(RecSummaryKeyMapping.storeSales, StoreSales),
                        storeMargin: ModelMapper.toModel(RecSummaryKeyMapping.storeMargin, StoreMargin),
                        storeTransactionCount: ModelMapper.toModel(RecSummaryKeyMapping.storeTransactionCount, StoreTransactionCount),
                        performancePeriodStart: RecSummaryKeyMapping.performancePeriodStart,
                        performancePeriodEnd: RecSummaryKeyMapping.performancePeriodEnd
                    };
                    return [2 /*return*/, mappedRecommendationSummary];
            }
        });
    }); },
    getPricePerformanceEntities: function (franchiseId, recommendationRoundId) { return __awaiter(void 0, void 0, void 0, function () {
        var url, response, finalEntities, _i, _a, entity, entityModel, mappedEntity, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!recommendationRoundId || recommendationRoundId.trim() === "") {
                        logger.error("Empty recommendation round ID provided to getPricePerformanceEntities");
                        return [2 /*return*/, []];
                    }
                    url = "/price-performance/entities?franchise_id=".concat(franchiseId, "&recommendation_round_id=").concat(recommendationRoundId);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.request({
                            url: url,
                            method: "GET"
                        })];
                case 2:
                    response = _b.sent();
                    if (!response.data || !response.data.data) {
                        logger.error("Error fetching price performance entities data from API");
                        return [2 /*return*/, []];
                    }
                    finalEntities = [];
                    for (_i = 0, _a = response.data.data; _i < _a.length; _i++) {
                        entity = _a[_i];
                        try {
                            entityModel = ModelMapper.toModel(entity, RecommendationSummary);
                            mappedEntity = __assign(__assign({}, entityModel), { storeSales: ModelMapper.toModel(entityModel.storeSales, StoreSales), storeMargin: ModelMapper.toModel(entityModel.storeMargin, StoreMargin), storeTransactionCount: ModelMapper.toModel(entityModel.storeTransactionCount, StoreTransactionCount) });
                            finalEntities.push(mappedEntity);
                        }
                        catch (mappingError) {
                            logger.error("Error mapping entity:", mappingError);
                        }
                    }
                    return [2 /*return*/, finalEntities];
                case 3:
                    error_1 = _b.sent();
                    logger.error("Error in getPricePerformanceEntities:", error_1);
                    return [2 /*return*/, []];
                case 4: return [2 /*return*/];
            }
        });
    }); },
    getStorePerformanceEntities: function (franchiseId, recommendationRoundId, entityId) { return __awaiter(void 0, void 0, void 0, function () {
        var url, response, finalEntities, _i, _a, entity, entityModel, mappedEntity, error_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!recommendationRoundId || recommendationRoundId.trim() === "") {
                        logger.error("Empty recommendation round ID provided to getStorePerformanceEntities");
                        return [2 /*return*/, []];
                    }
                    url = "/price-performance/stores?franchise_id=".concat(franchiseId, "&recommendation_round_id=").concat(recommendationRoundId, "&entity_id=").concat(entityId);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.request({
                            url: url,
                            method: "GET"
                        })];
                case 2:
                    response = _b.sent();
                    if (!response.data || !response.data.data) {
                        logger.error("Error fetching store performance entities data from API");
                        return [2 /*return*/, []];
                    }
                    finalEntities = [];
                    for (_i = 0, _a = response.data.data; _i < _a.length; _i++) {
                        entity = _a[_i];
                        try {
                            entityModel = ModelMapper.toModel(entity, RecommendationSummary);
                            mappedEntity = __assign(__assign({}, entityModel), { storeSales: ModelMapper.toModel(entityModel.storeSales, StoreSales), storeMargin: ModelMapper.toModel(entityModel.storeMargin, StoreMargin), storeTransactionCount: ModelMapper.toModel(entityModel.storeTransactionCount, StoreTransactionCount) });
                            finalEntities.push(mappedEntity);
                        }
                        catch (mappingError) {
                            logger.error("Error mapping entity:", mappingError);
                        }
                    }
                    return [2 /*return*/, finalEntities];
                case 3:
                    error_2 = _b.sent();
                    logger.error("Error in getStorePerformanceEntities:", error_2);
                    return [2 /*return*/, []];
                case 4: return [2 /*return*/];
            }
        });
    }); },
    getProductPerformanceMargins: function (franchiseId, recommendationRoundId, storeId) { return __awaiter(void 0, void 0, void 0, function () {
        var url, response, ProductMargins, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!recommendationRoundId || recommendationRoundId.trim() === "") {
                        logger.error("Empty recommendation round ID provided to getProductPerformanceMargins");
                        return [2 /*return*/, []];
                    }
                    url = "/price-performance/products?franchise_id=".concat(franchiseId, "&recommendation_round_id=").concat(recommendationRoundId, "&store_id=").concat(storeId);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.request({
                            url: url,
                            method: "GET"
                        })];
                case 2:
                    response = _a.sent();
                    if (!response.data || !response.data.data) {
                        logger.error("Error fetching product performance data from API");
                        return [2 /*return*/, []];
                    }
                    ProductMargins = response.data.data.map(function (item) { return ModelMapper.toModel(item, ProductMargin); });
                    return [2 /*return*/, ProductMargins];
                case 3:
                    error_3 = _a.sent();
                    logger.error("Error in getProductPerformanceEntities:", error_3);
                    return [2 /*return*/, []];
                case 4: return [2 /*return*/];
            }
        });
    }); }
};
