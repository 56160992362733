var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var _class, _descriptor, _descriptor2;
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { User } from "./User";
import { Property } from "@/utilities/decorators/property";
export var PriceAdvisor = (_class = /** @class */ (function (_super) {
    __extends(PriceAdvisor, _super);
    function PriceAdvisor() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.phone = (function () { return _initializerWarningHelper(_descriptor, _this); })();
        _this.email = (function () { return _initializerWarningHelper(_descriptor2, _this); })();
        return _this;
    }
    return PriceAdvisor;
}(User)), _descriptor = _applyDecoratedDescriptor(_class.prototype, "phone", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "email", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _class);
