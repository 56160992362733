var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { saveAs } from "file-saver";
import { api } from "../configs/axiosConfig";
import logger from "@/logger";
import { ModelMapper } from "@/mappers/ModelMapper";
import { ComparisonItem } from "@/types/competitorsPrice/ComparisonItem";
import { CompetitorItem } from "@/types/competitorsPrice/CompetitorItem";
import { Store as CmpStore } from "@/types/competitorsPrice/Store";
import { Address } from "@/types/recommendations/Address";
import { Store } from "@/types/recommendations/Store";
export var competitorPriceAPI = {
    getNearbyCompetitors: function (franchiseId, storeId) { return __awaiter(void 0, void 0, void 0, function () {
        var response, stores;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.request({
                        url: "store/competitors?franchise_id=".concat(franchiseId, "&store_id=").concat(storeId),
                        method: "GET"
                    })];
                case 1:
                    response = _a.sent();
                    if (!response.data) {
                        logger.error("Error when getting nearby competitors");
                        throw new Error("Error when getting nearby competitors");
                    }
                    stores = response.data.data.map(function (store) {
                        return ModelMapper.toModel(store, CmpStore);
                    });
                    return [2 /*return*/, {
                            stores: stores,
                            count: response.data.count,
                            cmprStoreIDs: response.data.cmpr_store_ids
                        }];
            }
        });
    }); },
    // CORRECT
    getCompetitorItems: function (franchiseId, storeID, cmprStoreID) { return __awaiter(void 0, void 0, void 0, function () {
        var response, competitorItems, cmprCategories, currentExtractDate;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.request({
                        url: "competitor/items/competitor?franchise_id=".concat(franchiseId, "&store_id=").concat(storeID, "&cmpr_store_id=").concat(cmprStoreID),
                        method: "GET"
                    })];
                case 1:
                    response = _a.sent();
                    competitorItems = response.data.data.map(function (item) {
                        var mappedItem = ModelMapper.toModel(item, CompetitorItem);
                        return mappedItem;
                    });
                    cmprCategories = Array.from(new Set(competitorItems.map(function (item) {
                        return item.cmprCategory;
                    })));
                    currentExtractDate = response.data.current_extract_date;
                    return [2 /*return*/, {
                            cmprCategories: cmprCategories,
                            competitorItems: competitorItems,
                            currentExtractDate: currentExtractDate
                        }];
            }
        });
    }); },
    // CORRECT
    getComparisonData: function (franchiseId, storeId, cmprStoreIds) { return __awaiter(void 0, void 0, void 0, function () {
        var response, _a, responseData, categories, cmpr_store_ids, data, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.request({
                            url: "/competitor/items/comparison?franchise_id=".concat(franchiseId) + "&store_id=".concat(storeId, "&cmpr_store_ids=").concat(cmprStoreIds.join("%2C")),
                            method: "GET"
                        })];
                case 1:
                    response = _b.sent();
                    _a = response.data, responseData = _a.data, categories = _a.categories, cmpr_store_ids = _a.cmpr_store_ids;
                    data = responseData.map(function (competitorData) {
                        return {
                            itemId: competitorData.item_id,
                            item: ModelMapper.toModel(competitorData.item, ComparisonItem),
                            competitorItems: Object.values(competitorData.cmpr_items).map(function (item) { return ModelMapper.toModel(item, CompetitorItem); })
                        };
                    });
                    return [2 /*return*/, {
                            data: data,
                            categories: categories,
                            cmprStoreIds: cmpr_store_ids
                        }];
                case 2:
                    error_1 = _b.sent();
                    logger.error("Error when getting comparison data: ".concat(error_1));
                    return [2 /*return*/, null];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    // TO DO - this function should be remove once all its references are replaced by getOwnStoresMapped
    getOwnStores: function (franchiseId, recommendationRoundId, signal) { return __awaiter(void 0, void 0, void 0, function () {
        var url, response, stores;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = "store/own?franchise_id=".concat(franchiseId);
                    if (recommendationRoundId) {
                        url += "&recommendation_round_id=".concat(recommendationRoundId);
                    }
                    return [4 /*yield*/, api.request({
                            url: url,
                            method: "GET",
                            signal: signal
                        })];
                case 1:
                    response = _a.sent();
                    if (!response.data) {
                        logger.error("Error when getting own stores");
                        throw new Error("Error when getting own stores");
                    }
                    stores = response.data.data.map(function (store) {
                        var mappedStore = ModelMapper.toModel(store, Store);
                        mappedStore.address = ModelMapper.toModel(store.address, Address);
                        return ModelMapper.flatten(mappedStore);
                    });
                    return [2 /*return*/, {
                            stores: stores,
                            count: response.data.count,
                            states: response.data.states,
                            storeIds: response.data.store_ids,
                            entityIds: response.data.entity_ids
                        }];
            }
        });
    }); },
    getOwnStoresMapped: function (franchiseId, signal) { return __awaiter(void 0, void 0, void 0, function () {
        var response, stores;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.request({
                        url: "store/own?franchise_id=".concat(franchiseId),
                        method: "GET",
                        signal: signal
                    })];
                case 1:
                    response = _a.sent();
                    if (!response.data) {
                        logger.error("Error when getting own stores");
                        throw new Error("Error when getting own stores");
                    }
                    stores = response.data.data.map(function (store) {
                        var mappedStore = ModelMapper.toModel(store, Store);
                        mappedStore.address = ModelMapper.toModel(mappedStore.address, Address);
                        return ModelMapper.flatten(mappedStore);
                    });
                    return [2 /*return*/, {
                            stores: stores,
                            count: response.data.count,
                            states: response.data.states,
                            storesIds: response.data.stores_ids,
                            entityIds: response.data.entity_ids
                        }];
            }
        });
    }); },
    // CORRECT
    downloadCompetitorItems: function (franchiseId, storeId, cmprStoreID, fileName) { return __awaiter(void 0, void 0, void 0, function () {
        var response, blob, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.request({
                            url: "competitor/items/competitor/download?franchise_id=".concat(franchiseId) + "&store_id=".concat(storeId, "&cmpr_store_id=").concat(cmprStoreID),
                            method: "GET",
                            responseType: "blob"
                        })];
                case 1:
                    response = _a.sent();
                    window.URL.createObjectURL(new Blob([response.data]));
                    blob = new Blob([response.data], {
                        type: "text/csv;charset=utf-8"
                    });
                    saveAs(blob, "".concat(fileName));
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    logger.error("Error when downloading csv with franchiseId: ".concat(franchiseId, " and Competitor: ").concat(cmprStoreID, " - ").concat(error_2));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    downloadComparisonData: function (franchiseId, storeId, cmprStoreIds, fileName) { return __awaiter(void 0, void 0, void 0, function () {
        var response, blob, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.request({
                            url: "/competitor/items/comparison/download?franchise_id=".concat(franchiseId) + "&store_id=".concat(storeId, "&cmpr_store_ids=").concat(cmprStoreIds.join("%2C")),
                            method: "GET",
                            responseType: "blob"
                        })];
                case 1:
                    response = _a.sent();
                    window.URL.createObjectURL(new Blob([response.data]));
                    blob = new Blob([response.data], {
                        type: "text/csv;charset=utf-8"
                    });
                    saveAs(blob, "".concat(fileName));
                    return [3 /*break*/, 3];
                case 2:
                    error_3 = _a.sent();
                    logger.error("Error when downloading Comparison csv with franchiseId: \n        ".concat(franchiseId, " and storeId: ").concat(storeId, " - ").concat(error_3));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }
};
