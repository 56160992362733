import { salesGroup, marginGroup, transactionGroup, createActionGroup } from "../utils";
export var TierPerformanceHeaderConfig = [{
        id: 1,
        columnName: "",
        color: "#F3EFFB",
        width: 120,
        maxWidth: 120,
        borderRight: true,
        subColumns: [{
                id: "storeSales.entityId",
                columnId: "storeSales.entityId",
                columnName: "Tier",
                visible: true,
                dollarSign: false,
                color: "#F3EFFB",
                minWidth: 120,
                accessor: function (row) { return row.storeSales.entityId; },
                borderRight: true,
                customValue: "storeSales.customTierName"
            }]
    }, salesGroup, marginGroup, transactionGroup, createActionGroup("tier")];
