import { useQuery } from "@tanstack/react-query";
import { UserAPI } from "@/apis/user/UserAPI";
export var useCurrentUser = function (params) { return useQuery({
    queryKey: ["currentUser"],
    queryFn: UserAPI.getCurrentUser,
    refetchOnMount: !(params === null || params === void 0 ? void 0 : params.useCachedData)
}); };
export var useUserManagement = function (params) { return useQuery({
    queryKey: ["userManagement"],
    queryFn: function () { return UserAPI.getUserManagagment(params.franchiseId); },
    refetchOnMount: !params.useCachedData,
    refetchOnWindowFocus: false
}); };
