// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes moveRight {
  0% {
    right: 0;
  }
  100% {
    right: 850px;
  }
}

.drawer-fab {
  position: absolute;
  animation: moveRight 200ms ease-in-out forwards;
}
`, "",{"version":3,"sources":["webpack://./src/views/Home/CompetitorPrice/CompetitorPrice.css"],"names":[],"mappings":"AAAA;EACE;IACE,QAAQ;EACV;EACA;IACE,YAAY;EACd;AACF;;AAEA;EACE,kBAAkB;EAClB,+CAA+C;AACjD","sourcesContent":["@keyframes moveRight {\n  0% {\n    right: 0;\n  }\n  100% {\n    right: 850px;\n  }\n}\n\n.drawer-fab {\n  position: absolute;\n  animation: moveRight 200ms ease-in-out forwards;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
