var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isEqual } from "lodash";
import { useContext, useCallback } from "react";
import { MainContext } from "@/views/Main Context/MainContext";
var useMainContext = function () {
    var _a, _b;
    var _c = useContext(MainContext), mainState = _c.mainState, setMainState = _c.setMainState;
    var updateEditionState = useCallback(function (newState, itemId, price) {
        setMainState(function (prevMainState) {
            var _a;
            var _b;
            if (!prevMainState.prices) {
                return prevMainState;
            }
            return __assign(__assign({}, prevMainState), { prices: __assign(__assign({}, prevMainState.prices), { activeEdits: __assign(__assign({}, prevMainState.prices.activeEdits), { activeItem: itemId, items: __assign(__assign({}, prevMainState.prices.activeEdits.items), (_a = {}, _a[itemId] = {
                            editingState: newState,
                            price: (_b = price !== null && price !== void 0 ? price : prevMainState.prices.activeEdits.items[itemId].price) !== null && _b !== void 0 ? _b : 0
                        }, _a)) }) }) });
        });
    }, [setMainState]);
    var setSelectedRecommendation = useCallback(function (newRecommendation) {
        setMainState(function (prevMainState) {
            return __assign(__assign({}, prevMainState), { selectedRecommendation: newRecommendation });
        });
    }, [setMainState]);
    var setFranchiseOwners = useCallback(function (franchiseOwners) {
        setMainState(function (prevMainState) {
            return __assign(__assign({}, prevMainState), { franchiseOwners: franchiseOwners });
        });
    }, [setMainState]);
    var getFranchiseOwners = useCallback(function () {
        return mainState.franchiseOwners;
    }, [mainState.franchiseOwners]);
    var getFranchiseId = useCallback(function () {
        var _a;
        return (_a = mainState.selectedFranchiseOwner) === null || _a === void 0 ? void 0 : _a.ownerOperatorId;
    }, [mainState.selectedFranchiseOwner]);
    var setFilters = useCallback(function (newFilters) {
        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
            forceUpdate: false
        };
        setMainState(function (prevMainState) {
            var _a, _b, _c;
            if (isEqual((_a = prevMainState.prices) === null || _a === void 0 ? void 0 : _a.filters, newFilters) && !options.forceUpdate) {
                return prevMainState;
            }
            return __assign(__assign({}, prevMainState), { prices: __assign(__assign({}, prevMainState.prices), { filters: __assign({}, newFilters), activeEdits: {
                        activeItem: (_b = prevMainState.prices) === null || _b === void 0 ? void 0 : _b.activeEdits.activeItem,
                        items: __assign({}, (_c = prevMainState.prices) === null || _c === void 0 ? void 0 : _c.activeEdits.items)
                    } }) });
        });
    }, [setMainState]);
    var setFranchiseOwner = useCallback(function (franchiseOwner) {
        setMainState(function (prevMainState) { return (__assign(__assign({}, prevMainState), { selectedFranchiseOwner: franchiseOwner })); });
    }, [setMainState]);
    var setLatestRecommendationRound = useCallback(function (latestRecommendationRound) {
        setMainState(function (prevMainState) { return (__assign(__assign({}, prevMainState), { latestRecommendationRound: latestRecommendationRound })); });
    }, [setMainState]);
    var getLatestRecommendationRound = useCallback(function () {
        return mainState.latestRecommendationRound;
    }, [mainState.latestRecommendationRound]);
    var resetActiveEdits = useCallback(function (uuidToReset) {
        var _a;
        var currentActiveEdits = (_a = mainState.prices) === null || _a === void 0 ? void 0 : _a.activeEdits.items;
        var updatedActiveEdits = {};
        if (currentActiveEdits && uuidToReset) {
            updatedActiveEdits = Object.entries(currentActiveEdits).reduce(function (filtered, _ref) {
                var uuid = _ref[0], value = _ref[1];
                if (!uuidToReset.includes(uuid)) {
                    filtered[uuid] = value;
                }
                return filtered;
            }, {});
        }
        setMainState(function (prevMainState) {
            var _a;
            return __assign(__assign({}, prevMainState), { prices: __assign(__assign({}, prevMainState.prices), { filters: ((_a = prevMainState.prices) === null || _a === void 0 ? void 0 : _a.filters) || {}, activeEdits: {
                        activeItem: undefined,
                        items: __assign({}, updatedActiveEdits)
                    } }) });
        });
    }, [(_a = mainState.prices) === null || _a === void 0 ? void 0 : _a.activeEdits, setMainState]);
    var getSelectedRecommendation = useCallback(function () {
        return mainState.selectedRecommendation;
    }, [mainState.selectedRecommendation]);
    var getActiveEdits = useCallback(function () {
        var _a;
        return (_a = mainState.prices) === null || _a === void 0 ? void 0 : _a.activeEdits;
    }, [(_b = mainState.prices) === null || _b === void 0 ? void 0 : _b.activeEdits]);
    var getFranchiseOwner = useCallback(function () {
        return mainState.selectedFranchiseOwner;
    }, [mainState.selectedFranchiseOwner]);
    var getIsDrawerOpen = useCallback(function () {
        return mainState.layout.isDrawerOpen;
    }, [mainState.layout.isDrawerOpen]);
    var setIsDrawerOpen = useCallback(function (isDrawerOpen) {
        setMainState(function (prevMainState) { return (__assign(__assign({}, prevMainState), { layout: __assign(__assign({}, prevMainState.layout), { isDrawerOpen: isDrawerOpen }) })); });
    }, [setMainState]);
    return {
        updateEditionState: updateEditionState,
        getFranchiseId: getFranchiseId,
        setFilters: setFilters,
        setSelectedRecommendation: setSelectedRecommendation,
        resetActiveEdits: resetActiveEdits,
        setFranchiseOwner: setFranchiseOwner,
        getSelectedRecommendation: getSelectedRecommendation,
        getActiveEdits: getActiveEdits,
        getFranchiseOwner: getFranchiseOwner,
        setFranchiseOwners: setFranchiseOwners,
        getFranchiseOwners: getFranchiseOwners,
        getIsDrawerOpen: getIsDrawerOpen,
        setIsDrawerOpen: setIsDrawerOpen,
        setLatestRecommendationRound: setLatestRecommendationRound,
        getLatestRecommendationRound: getLatestRecommendationRound
    };
};
export default useMainContext;
