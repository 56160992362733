// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inner-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgba(255, 255, 255, 1);
  padding: 1.5em;
  border: 1px solid rgba(235, 235, 235, 1);
  border-radius: 4px;
  height: 16em;
}
`, "",{"version":3,"sources":["webpack://./src/views/Tier  View/Main Tier Section/MainTierSection.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,uBAAuB;EACvB,wCAAwC;EACxC,cAAc;EACd,wCAAwC;EACxC,kBAAkB;EAClB,YAAY;AACd","sourcesContent":[".inner-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  background-color: rgba(255, 255, 255, 1);\n  padding: 1.5em;\n  border: 1px solid rgba(235, 235, 235, 1);\n  border-radius: 4px;\n  height: 16em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
