import { styled, TableCell, tableCellClasses } from "@mui/material";
export var advisorDetails = {
    name: "John Doe",
    email: "jdoe@example.com",
    phone: "(501) 555-3456",
    designation: "Pricing Advisor"
};
export var operationDetails = {
    hours: "9:00 AM - 5:00 PM (EST)",
    days: "Monday - Friday"
};
//Franchise Owner
export var ownerDetails = {
    name: "Brown, Michael",
    email: "mbrown@example.com",
    phone: "(501) 555-3457",
    designation: "Franchise Owner"
};
export var delegateDetails = [{
        name: "Holt, Raymond",
        email: "rholt99@example.com",
        phone: "(501) 555-1234",
        designation: "Delegate"
    }, {
        name: "Swanson, Ron",
        email: "swansonpnr@example.com",
        phone: "(501) 555-5678",
        designation: "Delegate"
    }, {
        name: "Barnes, Troy",
        email: "tbones@example.com",
        phone: "(501) 555-4568",
        designation: "Delegate"
    }, {
        name: "Fring, Gus",
        email: "gfring@example.com",
        phone: "(501) 555-3458",
        designation: "Delegate"
    }, {
        name: "Lee, Sarah",
        email: "slee@example.com",
        phone: "(501) 555-457",
        designation: "Delegate"
    }, {
        name: "Johnson, Robert",
        email: "rjohnson@example.com",
        phone: "(501) 555-3460",
        designation: "Delegate"
    }];
export var menuItemHeaderData = [{
        id: 1,
        columnId: "storeId",
        columnName: "Store ID",
        color: "rgba(53, 151, 193, 0.04)",
        dollarSign: false,
        visible: true,
        sort: true
    }, {
        id: 2,
        columnId: "tier",
        columnName: "Tier",
        color: "rgba(53, 151, 193, 0.04)",
        //   width: 165,
        visible: true,
        dollarSign: false,
        sort: true
    }, {
        id: 3,
        columnId: "street",
        columnName: "Street",
        color: "rgba(53, 151, 193, 0.04)",
        //   width: 220,
        visible: true,
        sort: true
    }, {
        id: 4,
        columnId: "city",
        columnName: "City",
        color: "rgba(53, 151, 193, 0.04)",
        //   width: 165,
        visible: true,
        dollarSign: false,
        sort: true
    }, {
        id: 5,
        columnId: "state",
        columnName: "State",
        color: "rgba(53, 151, 193, 0.04)",
        //   width: 165,
        dollarSign: false,
        visible: true,
        sort: true
    }];
export var menuItemRows = [{
        storeId: "21345",
        tier: "Tier01",
        street: "Street 1",
        city: "City 1",
        state: "State 1"
    }, {
        storeId: "21346",
        tier: "Tier01",
        street: "Street 2",
        city: "City 2",
        state: "State 2"
    }, {
        storeId: "72134",
        tier: "Tier03",
        street: "Street 3",
        city: "City 3",
        state: "State 3"
    }, {
        storeId: "72135",
        tier: "Tier03",
        street: "Street 4",
        city: "City 4",
        state: "State 4"
    }, {
        storeId: "83419",
        tier: "Tier02",
        street: "Street 5",
        city: "City 5",
        state: "State 5"
    }, {
        storeId: "12135",
        tier: "Tier02",
        street: "Street 6",
        city: "City 6",
        state: "State 6"
    }];
export var tierArray = ["All", "Tier01", "Tier02", "Tier03"];
export var cityArray = ["All", "City 1", "City 2", "City 3", "City 4", "City 5", "City 6"];
export var stateArray = ["All", "State 1", "State 2", "State 3", "State 4", "State 5", "State 6"];
export function getColumnToOrderBy(property, orderDirection) {
    var sense = orderDirection === "asc" ? "max" : "min";
    var column_to_order = property + "_" + sense;
    return column_to_order;
}
export var StyledTableCell = styled(TableCell)(function () {
    var _a;
    return (_a = {},
        _a["&.".concat(tableCellClasses.head)] = {
            color: "rgba(33, 33, 33, 1)",
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 600,
            borderBottom: "0px"
        },
        _a["&.".concat(tableCellClasses.body)] = {
            color: "rgba(33, 33, 33, 1)",
            fontSize: 16,
            fontFamily: "Open Sans",
            fontWeight: 400,
            borderBottom: "1px solid #D9D9D9",
            borderTop: "1px solid #D9D9D9",
            "&:first-of-type": {
                borderTopLeftRadius: 4,
                borderBottomLeftRadius: 4,
                borderLeft: "1px solid #D9D9D9"
            },
            "&:last-of-type": {
                borderTopRightRadius: 4,
                borderBottomRightRadius: 4,
                borderRight: "1px solid #D9D9D9"
            }
        },
        _a);
});
export function getComparator(order, orderBy) {
    return function (a, b) {
        if (order === "desc") {
            return descendingComparator(a, b, orderBy);
        }
        return -descendingComparator(a, b, orderBy);
    };
}
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
export function stableSort(array, comparator // eslint-disable-line no-unused-vars
) {
    var stabilizedThis = array === null || array === void 0 ? void 0 : array.map(function (el, index) { return [el, index]; });
    stabilizedThis === null || stabilizedThis === void 0 ? void 0 : stabilizedThis.sort(function (a, b) {
        var order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis === null || stabilizedThis === void 0 ? void 0 : stabilizedThis.map(function (el) { return el[0]; });
}
