// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.competitor-card-container {
  width: 1100px;
  overflow-x: auto;
  padding-bottom: 10px;
}

.competitor-card-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.competitor-card-container::-webkit-scrollbar-track {
  background: transparent;
}

.competitor-card-container::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/views/Home/CompetitorPrice/ComparisonModal/ComparisonModal.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[".competitor-card-container {\n  width: 1100px;\n  overflow-x: auto;\n  padding-bottom: 10px;\n}\n\n.competitor-card-container::-webkit-scrollbar {\n  width: 8px;\n  height: 8px;\n}\n\n.competitor-card-container::-webkit-scrollbar-track {\n  background: transparent;\n}\n\n.competitor-card-container::-webkit-scrollbar-thumb {\n  background: #d9d9d9;\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
