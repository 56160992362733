export var loaderContainerStyles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: "32px 0"
};
export var loaderGifStyles = function (size) { return ({
    height: "".concat(size, "px")
}); };
export var loaderTextStyles = {
    marginTop: "0",
    fontSize: "16px"
};
