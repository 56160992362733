import { Box, Menu, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
export var DropdownBox = styled(Box)({
    display: "inline-flex",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none"
});
export var DropdownMenu = styled(Menu)({
    ".MuiMenu-paper": {
        width: "362px",
        maxHeight: "292px",
        marginTop: ".5em"
    }
});
export var DropdownTextField = styled(TextField)({
    width: "100%",
    height: "40px"
});
