import { ORDER_BY } from "@/apis/types";
import "./DataTable.css";
export var cellByRow = function (row, headerData) {
    return headerData.map(function (header) {
        var _a;
        if (header.columnId === "actionButton") {
            return {
                key: header.columnId,
                value: null
            };
        }
        var subColumnId = (_a = header.subColumnId) !== null && _a !== void 0 ? _a : "";
        var newValue = subColumnId ? row[subColumnId][header.columnId] : row[header.columnId];
        return {
            key: header.columnId,
            value: newValue
        };
    });
};
export function getComparator(order, orderBy) {
    return function (a, b) {
        if (order === ORDER_BY.DESC) {
            return descendingComparator(a, b, orderBy);
        }
        return -descendingComparator(a, b, orderBy);
    };
}
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
export function stableSort(array, comparator) {
    var stabilizedThis = array === null || array === void 0 ? void 0 : array.map(function (el, index) { return [el, index]; });
    stabilizedThis === null || stabilizedThis === void 0 ? void 0 : stabilizedThis.sort(function (a, b) {
        var order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis === null || stabilizedThis === void 0 ? void 0 : stabilizedThis.map(function (el) { return el[0]; });
}
//EDITION
export var fontWeightByCell = function (cellKey, itemId, activeEdits) {
    var _a;
    var fontWeight = itemId === (activeEdits === null || activeEdits === void 0 ? void 0 : activeEdits.activeItem) && ["saved", "large_price_change_warning", "zero_price_warning"].includes((_a = activeEdits.items[itemId]) === null || _a === void 0 ? void 0 : _a.editingState) && (cellKey === "yourSubmittedPrice" || cellKey === "priceChange" || cellKey === "marginImpactRange") ? "600" : "400";
    fontWeight = cellKey === "recommendedPrice" ? "600" : fontWeight;
    return fontWeight;
};
export var fontColorByCell = function (cellKey, itemId, activeEdits) {
    var _a;
    var fontColor = itemId === (activeEdits === null || activeEdits === void 0 ? void 0 : activeEdits.activeItem) && ["saved", "large_price_change_warning", "zero_price_warning"].includes((_a = activeEdits.items[itemId]) === null || _a === void 0 ? void 0 : _a.editingState) && (cellKey === "yourSubmittedPrice" || cellKey === "priceChange" || cellKey === "marginImpactRange") ? "#1BA4DE" : "#212121";
    return fontColor;
};
export var formatLongString = function (cellKey, value) {
    if (cellKey === "itemName" && value.length > 20) {
        return value.substring(0, 20) + "...";
    }
    return value;
};
export var getCellProperties = function (currentColumn) { return ({
    showCell: currentColumn === null || currentColumn === void 0 ? void 0 : currentColumn.visible,
    isLinkByColumn: currentColumn === null || currentColumn === void 0 ? void 0 : currentColumn.isLink,
    showDollar: currentColumn === null || currentColumn === void 0 ? void 0 : currentColumn.dollarSign,
    isButton: currentColumn === null || currentColumn === void 0 ? void 0 : currentColumn.actionButton,
    showEllipsis: currentColumn === null || currentColumn === void 0 ? void 0 : currentColumn.ellipsis,
    dollarColor: currentColumn === null || currentColumn === void 0 ? void 0 : currentColumn.dollarColor,
    renderComponent: currentColumn === null || currentColumn === void 0 ? void 0 : currentColumn.renderComponent
}); };
export var checkIsTierRow = function (rows) { return (rows === null || rows === void 0 ? void 0 : rows.length) > 0 ? "tier" in rows[0] : false; };
export var coerceValue = function (value) {
    if (typeof value === "string") {
        var numericPart = value.replace(/[^\d.-]/g, "");
        if (!isNaN(parseFloat(numericPart))) {
            return parseFloat(numericPart);
        }
    }
    return value !== null && value !== void 0 ? value : "";
};
export function getValue(row, col, showCustomValues) {
    if (!col) {
        return undefined;
    }
    if (col.customValue && showCustomValues) {
        var key = col.customValue;
        if (typeof key === "string" && key.includes(".")) {
            var keys = key.split(".");
            var customValue = keys.reduce(function (acc, curr) { return acc && acc[curr]; }, row);
            if (customValue !== undefined && customValue !== null) {
                return customValue;
            }
        }
        else {
            var customValue = row[key];
            if (customValue !== undefined && customValue !== null) {
                return customValue;
            }
        }
    }
    if (typeof col.accessor === "function") {
        return col.accessor(row);
    }
    return row[col.columnId];
}
export var getNestedValue = function (obj, path) {
    return path.split(".").reduce(function (acc, key) { return acc === null || acc === void 0 ? void 0 : acc[key]; }, obj);
};
