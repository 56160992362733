import calendarIcon from "../../public/assets/calendar.svg";
import compareIcon from "../../public/assets/compareArrows.svg";
import newReleases from "../../public/assets/new_releases.svg";
import performanceIcon from "../../public/assets/performanceIcon.svg";
import personIcon from "../../public/assets/person.svg";
import storeIcon from "../../public/assets/store.svg";
export var navItems = [{
        label: "Pricing Rounds",
        icon: calendarIcon,
        path: "/pricing-rounds"
    }, {
        label: "Competitor Prices",
        icon: compareIcon,
        path: "/competitor-prices"
    }, {
        label: "Pricing Tiers",
        icon: storeIcon,
        path: "/pricing-tiers"
    }, {
        label: "Performance",
        icon: performanceIcon,
        path: "/price-performance"
    }, {
        label: "Users",
        icon: personIcon,
        path: "/users"
    }];
export var whatsNewItem = {
    label: "What's New",
    icon: newReleases
};
