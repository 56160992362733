export var cellByRow = function (row) {
    return Object.keys(row).filter(function (key) { return key !== "id"; }).map(function (key) {
        return {
            key: key,
            value: row[key]
        };
    });
};
export function getComparator(order, orderBy) {
    return function (a, b) {
        if (order === "desc") {
            return descendingComparator(a, b, orderBy);
        }
        return -descendingComparator(a, b, orderBy);
    };
}
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
export function stableSort(array, comparator) {
    var stabilizedThis = array === null || array === void 0 ? void 0 : array.map(function (el, index) { return [el, index]; });
    stabilizedThis === null || stabilizedThis === void 0 ? void 0 : stabilizedThis.sort(function (a, b) {
        var order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis === null || stabilizedThis === void 0 ? void 0 : stabilizedThis.map(function (el) { return el[0]; });
}
//EDITION
export var fontWeightByCell = function (cellKey, itemId, activeEdits) {
    var _a;
    var fontWeight = itemId === (activeEdits === null || activeEdits === void 0 ? void 0 : activeEdits.activeItem) && ["saved", "large_price_change_warning", "zero_price_warning"].includes((_a = activeEdits.items[itemId]) === null || _a === void 0 ? void 0 : _a.editingState) && (cellKey === "yourSubmittedPrice" || cellKey === "priceChange" || cellKey === "marginImpactRange") ? "600" : "400";
    fontWeight = cellKey === "recommendedPrice" ? "600" : fontWeight;
    return fontWeight;
};
export var fontColorByCell = function (cellKey, itemId, activeEdits) {
    var _a;
    var fontColor = itemId === (activeEdits === null || activeEdits === void 0 ? void 0 : activeEdits.activeItem) && ["saved", "large_price_change_warning", "zero_price_warning"].includes((_a = activeEdits.items[itemId]) === null || _a === void 0 ? void 0 : _a.editingState) && (cellKey === "yourSubmittedPrice" || cellKey === "priceChange" || cellKey === "marginImpactRange") ? "#1BA4DE" : "#212121";
    return fontColor;
};
export var formatLongString = function (cellKey, value) {
    if (cellKey === "menuItem" && value.length > 20) {
        return value.substring(0, 20) + "...";
    }
    return value;
};
