import { useQuery } from "@tanstack/react-query";
import { competitorPriceAPI } from "@/apis/CompetitorPricesAPI/competitorPriceAPI";
export var useNearbyCompetitors = function (franchiseId, storeId) { return useQuery({
    queryKey: ["nearbyCompetitors"],
    queryFn: function () { return competitorPriceAPI.getNearbyCompetitors(franchiseId, storeId); },
    refetchOnMount: true
}); };
export var useOwnStores = function (franchiseId, recommendationRoundId, signal) { return useQuery({
    queryKey: ["ownStores"],
    queryFn: function () {
        if (!franchiseId) {
            throw Error("Missing franchiseId when fetching own stores");
        }
        return competitorPriceAPI.getOwnStores(franchiseId, recommendationRoundId, signal);
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false
}); };
export var useCompetitorItems = function (franchiseId, storeId, cmprStoreId) { return useQuery({
    queryKey: ["competitorItems"],
    queryFn: function () {
        return competitorPriceAPI.getCompetitorItems(franchiseId, storeId, cmprStoreId);
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false
}); };
