// Formats a string with title case.
// @param {*} value The string eg. 'active Customers'
import { isNumber } from "lodash";
// @returns {string} Title cased string eg. 'Active Customers'
export function TitleCase(value) {
    return value.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}
// Formats a string by replacing _ with space.
// @param {*} value The string eg. 'active_customers'
// @returns {string} Title cased string eg. 'active customers'
export function AddUnderscoreSpace(value) {
    return value.replace(/_/g, " ");
}
// Formats a string by replacing camelcase with space and capitalize.
export function camelCaseToCapitalizedSpace(value) {
    // Insert a space before each capital letter and convert to lowercase
    var spacedString = value.replace(/([A-Z])/g, " $1").toLowerCase();
    // Capitalize the first letter of each word
    var capitalizedString = spacedString.replace(/(^|\s)\S/g, function (match) {
        return match.toUpperCase();
    });
    return capitalizedString;
}
// Format current date for runId
export function formatDate(date) {
    var year = date.getFullYear().toString();
    var month = (date.getMonth() + 1).toString().padStart(2, "0");
    var day = date.getDate().toString().padStart(2, "0");
    var hours = date.getHours().toString().padStart(2, "0");
    var minutes = date.getMinutes().toString().padStart(2, "0");
    var seconds = date.getSeconds().toString().padStart(2, "0");
    var milliseconds = date.getMilliseconds().toString().padStart(3, "0");
    return "".concat(year, "!").concat(month, "!").concat(day, "T").concat(hours) + "!".concat(minutes, "!").concat(seconds, "!").concat(milliseconds);
}
export function formatStringDate(dateString) {
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var date = new Date(dateString);
    var month = months[date.getMonth()];
    var day = date.getDate();
    var year = date.getFullYear();
    var hours = date.getHours();
    var minutes = date.getMinutes().toString().padStart(2, "0");
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    var formattedDate = "".concat(month, " ").concat(day, ", ").concat(year, " ").concat(hours, ":").concat(minutes).concat(ampm);
    return formattedDate;
}
export function camelCaseToNormalString(data) {
    var result = data.replace(/([A-Z])/g, " $1");
    var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
}
export var colors = function () {
    var color = ["#E8F6FC", "#E6F7F7", "#F3F8EB"];
    return color;
};
export var colorsRgba = function () {
    var color = ["rgba(232,246,252,1)", "rgba(230,247,247,1)", "rgba(243,248,235,1)"];
    return color;
};
function generateRandomValue(c) {
    var r = Math.random() * 16 | 0;
    var value = c === "x" ? r : r & 0x3 | 0x8;
    return value.toString(16);
}
export function generateUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, generateRandomValue);
}
export function getColumnToOrderBy(property, orderDirection) {
    var sense = orderDirection === "asc" ? "max" : "min";
    var column_to_order = property + "_" + sense;
    return column_to_order;
}
export function getFormattedValue(value) {
    var dollarSign = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var percentage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    if (typeof value === "string") {
        return value;
    }
    if (isNumber(value) && Math.abs(value) < 0.005) {
        value = 0;
    }
    var showDollarSign = dollarSign ? "$" : "";
    var showPercentage = percentage ? "%" : "";
    var newValue = isNumber(value) ? showDollarSign + value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }) + showPercentage : null;
    return newValue;
}
export function getColor(value, min, max) {
    var normalized = (value - min) / (max - min);
    var minColor = [0xe6, 0xf1, 0xf7]; // #3899C2 #E6F1F7
    var maxColor = [0x05, 0x6f, 0xad]; // #E1F5F1 #056FAD
    var color = minColor.map(function (channel, index) {
        var maxChannel = maxColor[index];
        return Math.round((maxChannel - channel) * normalized + channel);
    });
    return "rgb(".concat(color.join(", "), ")");
}
export function getContrastYIQ(color) {
    // Function to convert RGB to YIQ
    function rgbToYIQ(r, g, b) {
        return (r * 299 + g * 587 + b * 114) / 1000;
    }
    var r, g, b;
    // Check if the color is in hex format
    if (color.startsWith("#")) {
        // Convert hex color to RGB
        r = parseInt(color.substr(1, 2), 16);
        g = parseInt(color.substr(3, 2), 16);
        b = parseInt(color.substr(5, 2), 16);
    }
    else if (color.startsWith("rgb(")) {
        // Extract RGB values from string
        var rgbValues = color.substring(4, color.length - 1).split(",");
        r = parseInt(rgbValues[0]);
        g = parseInt(rgbValues[1]);
        b = parseInt(rgbValues[2]);
    }
    else {
        return "black";
    }
    // Calculate YIQ according to ITU-R BT.601
    var yiq = rgbToYIQ(r, g, b);
    // Return 'black' if YIQ is bright, 'white' otherwise
    return yiq >= 158 ? "black" : "white";
}
export function getPriceChange(originalPrice, updatedPrice) {
    return originalPrice - updatedPrice;
}
export var addStandardQueryParamsToUrl = function (limit, offset, sortBy, orderBy) {
    var newUrl = "";
    newUrl += "&limit=".concat(limit) + "&offset=".concat(offset) + "&sortBy=".concat(sortBy) + "&orderBy=".concat(orderBy);
    return newUrl;
};
export var checkAccess = function (allow, deny, permission) {
    if (deny.includes(permission)) {
        return false;
    }
    if (allow.includes(permission)) {
        return true;
    }
    return false;
};
export var getFormattedFloatNumber = function (value) {
    return parseFloat(value).toFixed(2);
};
export var appendDollarSign = function (value) {
    if (typeof value === "number" && value < 0) {
        return "$(".concat(Math.abs(value).toFixed(2), ")");
    }
    else if (value === undefined) {
        return "0";
    }
    else if (typeof value === "number") {
        return "$".concat(value.toFixed(2));
    }
    return ""; // Default return value
};
