var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var setCursorPointer = function (mapRef, layer) {
    mapRef.current.on("mouseenter", layer, function () {
        mapRef.current.getCanvas().style.cursor = "pointer";
    });
    mapRef.current.on("mouseleave", layer, function () {
        mapRef.current.getCanvas().style.cursor = "";
    });
};
export var getSourceSpecifications = function (points) {
    return {
        type: "geojson",
        data: {
            features: points,
            type: "FeatureCollection"
        },
        cluster: true,
        clusterMaxZoom: 11,
        clusterRadius: 50
    };
};
export var addClusterLayers = function (_ref) {
    var mapRef = _ref.mapRef, cluster = _ref.cluster, clusterCount = _ref.clusterCount, unclusterPoint = _ref.unclusterPoint;
    var getPointCount = ["get", "point_count"];
    var hasPointCount = ["has", "point_count"];
    var standardCircleRadius = ["step", getPointCount, 20, 25, 30, 50, 40];
    if (mapRef && mapRef.current) {
        // Add cluster layer
        mapRef.current.addLayer({
            id: cluster.layerName,
            type: "circle",
            source: cluster.dataSource,
            filter: hasPointCount,
            paint: {
                "circle-color": __spreadArray(["step", getPointCount], cluster.circleColor, true),
                "circle-radius": standardCircleRadius
            },
            layout: {}
        });
        // Add cluster count layer
        mapRef.current.addLayer({
            id: clusterCount.layerName,
            type: "symbol",
            source: clusterCount.dataSource,
            filter: hasPointCount,
            paint: {},
            layout: {
                "text-field": ["get", "point_count_abbreviated"],
                "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                "text-size": 12
            }
        });
        // Add unclustered point layer
        mapRef.current.addLayer({
            id: unclusterPoint.layerName,
            type: "circle",
            source: unclusterPoint.dataSource,
            filter: ["!", hasPointCount],
            paint: {
                "circle-color": ["get", "color"],
                "circle-radius": 12
            },
            layout: {}
        });
    }
};
