var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isEqual } from "lodash";
import { useContext, useCallback } from "react";
import { MainContext } from "@/views/Main Context/MainContext";
import { USER_ROLE } from "@/views/Main Context/utils";
var useMainContext = function () {
    var _a, _b, _c, _d, _e;
    var _f = useContext(MainContext), mainState = _f.mainState, setMainState = _f.setMainState;
    var updateEditionState = useCallback(function (newState, itemId, price) {
        setMainState(function (prevMainState) {
            var _a;
            var _b;
            if (!prevMainState.prices) {
                return prevMainState;
            }
            return __assign(__assign({}, prevMainState), { prices: __assign(__assign({}, prevMainState.prices), { activeEdits: __assign(__assign({}, prevMainState.prices.activeEdits), { activeItem: itemId, items: __assign(__assign({}, prevMainState.prices.activeEdits.items), (_a = {}, _a[itemId] = {
                            editingState: newState,
                            price: (_b = price !== null && price !== void 0 ? price : prevMainState.prices.activeEdits.items[itemId].price) !== null && _b !== void 0 ? _b : 0
                        }, _a)) }) }) });
        });
    }, [setMainState]);
    var setSelectedRecommendation = useCallback(
    // To Do: Update recommendation Typing
    function (newRecommendation) {
        setMainState(function (prevMainState) {
            return __assign(__assign({}, prevMainState), { selectedRecommendation: newRecommendation });
        });
    }, [setMainState]);
    var getItemByItemId = useCallback(function (itemId) {
        var _a, _b;
        return (_b = (_a = mainState.prices) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.find(function (item) { return item.item_id === itemId; });
    }, [(_a = mainState.prices) === null || _a === void 0 ? void 0 : _a.items]);
    var getFranchiseId = useCallback(function () {
        var currentUser = mainState.userInfo.user;
        var currentFranchiseOwner = mainState.userInfo.selectedFranchiseOwner;
        if (!currentUser) {
            return null;
        }
        var franchiseId = currentUser.owner_operator_id;
        if (currentUser.user_role_id === USER_ROLE.PRICE_ADVISOR) {
            if (!currentFranchiseOwner) {
                return null;
            }
            franchiseId = currentFranchiseOwner.owner_operator_id;
        }
        return franchiseId;
    }, [mainState.userInfo]);
    var updateItemById = useCallback(function (newItem) {
        var _a, _b;
        var updatedItems = (_b = (_a = mainState.prices) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.map(function (item) { return item.id === newItem.id ? newItem : item; });
        setMainState(function (prevMainState) {
            if (!prevMainState.prices) {
                return prevMainState;
            }
            return __assign(__assign({}, prevMainState), { prices: __assign(__assign({}, prevMainState.prices), { items: updatedItems }) });
        });
    }, [setMainState, (_b = mainState.prices) === null || _b === void 0 ? void 0 : _b.items]);
    var setItems = useCallback(function (items, totalItemCount) {
        setMainState(function (prevMainState) {
            var _a, _b;
            return __assign(__assign({}, prevMainState), { prices: __assign(__assign({}, prevMainState.prices), { items: items, totalItemCount: totalItemCount, activeEdits: {
                        activeItem: ((_a = prevMainState.prices) === null || _a === void 0 ? void 0 : _a.activeEdits.activeItem) || "",
                        items: __assign({}, (_b = prevMainState.prices) === null || _b === void 0 ? void 0 : _b.activeEdits.items)
                    } }) });
        });
    }, [setMainState]);
    var setItemCategories = useCallback(function (itemCategories) {
        setMainState(function (prevMainState) {
            var _a, _b;
            return __assign(__assign({}, prevMainState), { prices: __assign(__assign({}, prevMainState.prices), { itemCategories: itemCategories, activeEdits: {
                        activeItem: ((_a = prevMainState.prices) === null || _a === void 0 ? void 0 : _a.activeEdits.activeItem) || "",
                        items: __assign({}, (_b = prevMainState.prices) === null || _b === void 0 ? void 0 : _b.activeEdits.items)
                    } }) });
        });
    }, [setMainState]);
    var setEntity = useCallback(function (entity) {
        setMainState(function (prevMainState) {
            var _a, _b;
            return __assign(__assign({}, prevMainState), { prices: __assign(__assign({}, prevMainState.prices), { entity: entity, activeEdits: {
                        activeItem: (_a = prevMainState.prices) === null || _a === void 0 ? void 0 : _a.activeEdits.activeItem,
                        items: __assign({}, (_b = prevMainState.prices) === null || _b === void 0 ? void 0 : _b.activeEdits.items)
                    } }) });
        });
    }, [setMainState]);
    var setFilters = useCallback(function (newFilters) {
        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
            forceUpdate: false
        };
        setMainState(function (prevMainState) {
            var _a, _b, _c;
            if (isEqual((_a = prevMainState.prices) === null || _a === void 0 ? void 0 : _a.filters, newFilters) && !options.forceUpdate) {
                return prevMainState;
            }
            return __assign(__assign({}, prevMainState), { prices: __assign(__assign({}, prevMainState.prices), { filters: __assign({}, newFilters), activeEdits: {
                        activeItem: (_b = prevMainState.prices) === null || _b === void 0 ? void 0 : _b.activeEdits.activeItem,
                        items: __assign({}, (_c = prevMainState.prices) === null || _c === void 0 ? void 0 : _c.activeEdits.items)
                    } }) });
        });
    }, [setMainState]);
    var setUserInfo = useCallback(function (userInfo) {
        setMainState(function (prevMainState) {
            return __assign(__assign({}, prevMainState), { userInfo: userInfo });
        });
    }, [setMainState]);
    var setFranchiseOwner = useCallback(function (franchiseOwner) {
        setMainState(function (prevMainState) { return (__assign(__assign({}, prevMainState), { userInfo: __assign(__assign({}, prevMainState.userInfo), { selectedFranchiseOwner: franchiseOwner }) })); });
    }, [setMainState]);
    var resetActiveEdits = useCallback(function (uuidToReset) {
        var _a;
        var currentActiveEdits = (_a = mainState.prices) === null || _a === void 0 ? void 0 : _a.activeEdits.items;
        var updatedActiveEdits = {};
        if (currentActiveEdits && uuidToReset) {
            updatedActiveEdits = Object.entries(currentActiveEdits).reduce(function (filtered, _ref) {
                var uuid = _ref[0], value = _ref[1];
                if (!uuidToReset.includes(uuid)) {
                    filtered[uuid] = value;
                }
                return filtered;
            }, {});
        }
        setMainState(function (prevMainState) {
            return __assign(__assign({}, prevMainState), { prices: __assign(__assign({}, prevMainState.prices), { activeEdits: {
                        activeItem: undefined,
                        items: __assign({}, updatedActiveEdits)
                    } }) });
        });
    }, [(_c = mainState.prices) === null || _c === void 0 ? void 0 : _c.activeEdits, setMainState]);
    var getSelectedRecommendation = useCallback(function () {
        return mainState.selectedRecommendation;
    }, [mainState.selectedRecommendation]);
    var getSelectedEntity = useCallback(function () {
        var _a;
        return (_a = mainState.prices) === null || _a === void 0 ? void 0 : _a.entity;
    }, [(_d = mainState.prices) === null || _d === void 0 ? void 0 : _d.entity]);
    var getActiveEdits = useCallback(function () {
        var _a;
        return (_a = mainState.prices) === null || _a === void 0 ? void 0 : _a.activeEdits;
    }, [(_e = mainState.prices) === null || _e === void 0 ? void 0 : _e.activeEdits]);
    return {
        updateEditionState: updateEditionState,
        getItemByItemId: getItemByItemId,
        getFranchiseId: getFranchiseId,
        updateItemById: updateItemById,
        setItems: setItems,
        setItemCategories: setItemCategories,
        setEntity: setEntity,
        setFilters: setFilters,
        setSelectedRecommendation: setSelectedRecommendation,
        resetActiveEdits: resetActiveEdits,
        setUserInfo: setUserInfo,
        setFranchiseOwner: setFranchiseOwner,
        getSelectedRecommendation: getSelectedRecommendation,
        getSelectedEntity: getSelectedEntity,
        getActiveEdits: getActiveEdits
    };
};
export default useMainContext;
