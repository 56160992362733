export var listItemButtonStyles = function (open) { return ({
    height: 44,
    justifyContent: open ? "initial" : "center",
    backgroundColor: "white",
    px: 2.5,
    borderLeft: "2px solid transparent",
    "&.Mui-selected": {
        borderLeft: "4px solid #3597C1",
        backgroundColor: "white",
        "&:hover": {
            backgroundColor: "white"
        }
    },
    "&:hover": {
        backgroundColor: "#f0f0f0"
    }
}); };
export var listItemIconStyles = function (open) { return ({
    minWidth: 0,
    mr: open ? 2 : "auto",
    justifyContent: "center",
    "&:hover": {
        opacity: 1,
        boxShadow: "none"
    }
}); };
export var listItemTextStyles = function (isSelected) { return ({
    sx: {
        fontWeight: isSelected ? "600" : "normal"
    }
}); };
