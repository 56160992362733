var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
export var getStyledTableCell = function (isTierView, tableHeaderPadding, customHeaderStyle, customBodyStyle) { return styled(TableCell)(function () {
    var _a;
    return (_a = {},
        _a["&.".concat(tableCellClasses.head)] = __assign({ color: "rgba(33, 33, 33, 1)", fontSize: 14, fontFamily: "Open Sans", fontWeight: 600, borderBottom: "0.5em solid #FFFFFF", padding: tableHeaderPadding !== null && tableHeaderPadding !== void 0 ? tableHeaderPadding : "16px" }, customHeaderStyle),
        _a["&.".concat(tableCellClasses.body)] = __assign({ color: "rgba(33, 33, 33, 1)", fontSize: 16, fontFamily: "Open Sans", fontWeight: 400, borderBottom: !isTierView ? "1px solid #D9D9D9" : "0px" }, customBodyStyle),
        _a);
}); };
export var StickyTableRow = styled(TableRow)(function (_ref) {
    var topOffset = _ref.topOffset;
    return {
        position: "sticky",
        zIndex: 1,
        top: topOffset || 0
    };
});
export var tablePaginationContainer = {
    display: "flex",
    justifyContent: "center",
    marginTop: "12px"
};
export var tablePaginationStyles = {
    width: "max-content",
    ".MuiTablePagination-spacer": {
        display: "none"
    },
    ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows, .MuiTablePagination-select": {
        fontSize: 14
    },
    ".MuiTablePagination-select": {
        border: "1px solid #dedede",
        borderRadius: "4px"
    },
    ".MuiTablePagination-displayedRows": {
        margin: "0 32px",
        color: "#666666"
    },
    ".MuiToolbar-root": {
        padding: 0
    },
    ".MuiSvgIcon-root": {
        right: "8px"
    }
};
