import { Drawer } from "@mui/material";
import { styled } from "@mui/material/styles";
export var drawerWidth = 220;
export var drawerMiniWidth = 56;
export var AppDrawer = styled(Drawer, {
    shouldForwardProp: function (prop) { return prop !== "open"; }
})(function (_ref) {
    var theme = _ref.theme, open = _ref.open;
    return {
        width: open ? drawerWidth : drawerMiniWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        "& .MuiDrawer-paper": {
            width: open ? drawerWidth : drawerMiniWidth,
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                duration: theme.transitions.duration.standard,
                easing: theme.transitions.easing.easeInOut
            })
        }
    };
});
