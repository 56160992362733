import { styled, Box, Switch } from "@mui/material";
export var ContentContainer = styled(Box)(function () { return ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "1em",
    marginTop: "1em",
    alignItems: "center"
}); });
export var ActionButtonContainer = styled(Box)(function () { return ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "1em"
}); });
export var SwitchContainer = styled(Box)(function () { return ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "1em"
}); });
export var StyledSwitch = styled(Switch)(function () { return ({
    "& .MuiSwitch-switchBase": {
        "&.Mui-checked": {
            "+ .MuiSwitch-track": {
                backgroundColor: "#E2CEF8"
            },
            ".MuiSwitch-thumb": {
                backgroundColor: "#46137F"
            }
        }
    }
}); });
