// REVIEW: add Item ID here
export var groupLeaderHeaderRowData = [{
        id: 1,
        columnId: "itemId",
        columnName: "Item ID",
        color: "#f7fbfd",
        visible: true
    }, {
        id: 2,
        columnId: "itemName",
        columnName: "Menu Item",
        color: "#f7fbfd",
        visible: true
    }, {
        id: 3,
        columnId: "submittedPrice",
        columnName: "Price to Submit",
        color: "#f7fbfd",
        visible: true,
        dollarSign: true
    }, {
        id: 4,
        columnId: "currentPrice",
        columnName: "Current Price",
        color: "#f7fbfd",
        visible: true,
        dollarSign: true
    }, {
        id: 5,
        columnId: "priceChange",
        columnName: "Price Change",
        color: "#f7fbfd",
        visible: true,
        dollarSign: true
    }];
