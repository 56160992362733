// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.column-name-stack {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.info-icon {
  color: #0973a7;
  font-size: 1.5em !important;
  margin: 0px !important;
}
.table-paper {
  overflow: hidden;
  box-shadow: none;
}
.column-link {
  font-weight: 600;
  font-size: 16px;
  color: #46137f;
  text-decoration: none;
}
.check-box-icon {
  font-size: 1.5em !important;
  margin: 0px !important;
}
`, "",{"version":3,"sources":["webpack://./src/views/Home/CompetitorPrice/CompetitorTable/CompetitorTable.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,2BAA2B;EAC3B,sBAAsB;AACxB;AACA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,qBAAqB;AACvB;AACA;EACE,2BAA2B;EAC3B,sBAAsB;AACxB","sourcesContent":[".column-name-stack {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.info-icon {\n  color: #0973a7;\n  font-size: 1.5em !important;\n  margin: 0px !important;\n}\n.table-paper {\n  overflow: hidden;\n  box-shadow: none;\n}\n.column-link {\n  font-weight: 600;\n  font-size: 16px;\n  color: #46137f;\n  text-decoration: none;\n}\n.check-box-icon {\n  font-size: 1.5em !important;\n  margin: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
