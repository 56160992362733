var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { saveAs } from "file-saver";
import { api } from "../configs/axiosConfig";
import { ORDER_BY, SORT_BY } from "../types";
import { generateGetItemsAndCategoriesUrl, generateResetDefaultPricesUrl } from "../utils";
import logger from "@/logger";
export var recommendationAPI = {
    getRecommendations: function (status, franchiseId) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.request({
                            url: "/recommendation/overall?recommendation_status=".concat(status, "&franchise_id=").concat(franchiseId),
                            method: "GET"
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_1 = _a.sent();
                    logger.error("Error when fetching recommendations: ".concat(error_1));
                    return [2 /*return*/, null];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    getEntities: function (recommendationId, franchiseId) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.request({
                            url: "/recommendation/entities?recommendation_id=".concat(recommendationId, "&franchise_id=").concat(franchiseId),
                            method: "GET"
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data.data];
                case 2:
                    error_2 = _a.sent();
                    logger.error("Error when fetching tier list ".concat(error_2));
                    return [2 /*return*/, []];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    getStoreDetails: function (entityId, franchiseId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.request({
                        url: "/store/entity?entity_id=".concat(entityId, "&franchise_id=").concat(franchiseId),
                        method: "GET"
                    })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); },
    getEntity: function (entityRecommendationId, franchiseId) { return __awaiter(void 0, void 0, void 0, function () {
        var url, response, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    url = "/recommendation/entity?franchise_id=".concat(franchiseId, "&entity_recommendation_id=").concat(entityRecommendationId);
                    return [4 /*yield*/, api.request({
                            url: url,
                            method: "GET"
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_3 = _a.sent();
                    logger.error("Error in getEntities", error_3);
                    return [2 /*return*/, null];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    getItemsAndCategories: function (entityRecommendationId, filters) { return __awaiter(void 0, void 0, void 0, function () {
        var defaultParams, url, response, error_4;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    defaultParams = {
                        limit: 10,
                        offset: 0,
                        statusFlag: "RECOMMENDED",
                        orderBy: ORDER_BY.DESC,
                        sortBy: SORT_BY.WEEKLY_UNITS
                    };
                    url = generateGetItemsAndCategoriesUrl(entityRecommendationId, __assign(__assign({}, defaultParams), filters));
                    return [4 /*yield*/, api.request({
                            url: url,
                            method: "GET"
                        })];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, {
                            items: response.data.data,
                            itemCategories: response.data.meta_data.categories,
                            filteredCount: (_a = response.data.meta_data.filtered_count) !== null && _a !== void 0 ? _a : response.data.meta_data.count
                        }];
                case 2:
                    error_4 = _b.sent();
                    logger.error("Error in getItems", error_4);
                    return [2 /*return*/, null];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    getGroupItems: function (franchiseId, leaderUuid) { return __awaiter(void 0, void 0, void 0, function () {
        var url, response, error_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    url = "/recommendation/items/group?franchise_id=".concat(franchiseId, "&leader_item_uuid=").concat(leaderUuid);
                    return [4 /*yield*/, api.request({
                            url: url,
                            method: "GET"
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data.followers];
                case 2:
                    error_5 = _a.sent();
                    logger.error("Error when getting group items", error_5);
                    return [2 /*return*/, null];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    updateItem: function (requestBody) { return __awaiter(void 0, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = "/recommendation/item";
                    return [4 /*yield*/, api.request({
                            url: url,
                            method: "PATCH",
                            data: requestBody
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, (response === null || response === void 0 ? void 0 : response.data) || {}];
            }
        });
    }); },
    resetToDefault: function (franchiseId, entityRecommendationId, params) { return __awaiter(void 0, void 0, void 0, function () {
        var url, response, error_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    url = generateResetDefaultPricesUrl(entityRecommendationId, params);
                    return [4 /*yield*/, api.request({
                            url: url,
                            method: "PATCH"
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data.data];
                case 2:
                    error_6 = _a.sent();
                    logger.error("Error when resetting items with franchiseId: ".concat(franchiseId, " and entityRecommendationId: ").concat(entityRecommendationId, " - ").concat(error_6));
                    return [2 /*return*/, null];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    uploadCsv: function (franchiseId, datasetName, datasetId, csvFormData) { return __awaiter(void 0, void 0, void 0, function () {
        var url, response, error_7;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    if (!franchiseId) {
                        logger.error("Error when uploading csv with franchiseId: ".concat(franchiseId));
                    }
                    url = "/recommendation/items/upload?franchise_id=".concat(franchiseId, "&dataset_name=").concat(datasetName, "&dataset_id=").concat(datasetId);
                    return [4 /*yield*/, api.request({
                            url: url,
                            method: "POST",
                            data: csvFormData
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_7 = _a.sent();
                    logger.error("Error when uploading csv with franchiseId: ".concat(franchiseId, " and upload: ").concat(datasetName, " ").concat(datasetId, " - ").concat(error_7));
                    return [2 /*return*/, null];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    bulkUpdatePrices: function (franchiseId, datasetName, datasetId, data) { return __awaiter(void 0, void 0, void 0, function () {
        var url, response, error_8;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    url = "/recommendation/items/update?dataset_name=".concat(datasetName, "&dataset_id=").concat(datasetId);
                    return [4 /*yield*/, api.request({
                            url: url,
                            method: "PATCH",
                            data: data
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_8 = _a.sent();
                    logger.error("Error when uploading csv with franchiseId: ".concat(franchiseId, " and upload: ").concat(datasetName, " ").concat(datasetId, " - ").concat(error_8));
                    return [2 /*return*/, null];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    downloadCsv: function (franchiseId, downloadName, downloadId, fileName) { return __awaiter(void 0, void 0, void 0, function () {
        var url, response, blob, error_9;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    url = "/recommendation/items/download?franchise_id=".concat(franchiseId, "&dataset_name=").concat(downloadName, "&dataset_id=").concat(downloadId);
                    return [4 /*yield*/, api.request({
                            url: url,
                            method: "GET",
                            responseType: "blob"
                        })];
                case 1:
                    response = _a.sent();
                    window.URL.createObjectURL(new Blob([response.data]));
                    blob = new Blob([response.data], {
                        type: "text/csv;charset=utf-8"
                    });
                    saveAs(blob, "".concat(fileName));
                    return [3 /*break*/, 3];
                case 2:
                    error_9 = _a.sent();
                    logger.error("Error when downloading csv with franchiseId: ".concat(franchiseId, " and download: ").concat(downloadName, " ").concat(downloadId, " - ").concat(error_9));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    submitTier: function (franchiseId, entityRecommendationId) { return __awaiter(void 0, void 0, void 0, function () {
        var url, response, error_10;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    url = "/recommendation/submit/entity?franchise_id=".concat(franchiseId, "&entity_recommendation_id=").concat(entityRecommendationId);
                    return [4 /*yield*/, api.request({
                            url: url,
                            method: "POST"
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, (response === null || response === void 0 ? void 0 : response.data) || {}];
                case 2:
                    error_10 = _a.sent();
                    logger.error("Error when submitting tier prices ".concat(error_10));
                    return [2 /*return*/, null];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    submitAllTier: function (franchiseId, recommendationId) { return __awaiter(void 0, void 0, void 0, function () {
        var url, response, error_11;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    url = "/recommendation/submit/entities?franchise_id=".concat(franchiseId, "&recommendation_id=").concat(recommendationId);
                    return [4 /*yield*/, api.request({
                            url: url,
                            method: "POST"
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, (response === null || response === void 0 ? void 0 : response.data) || {}];
                case 2:
                    error_11 = _a.sent();
                    logger.error("Error when submitting all tier prices ".concat(error_11));
                    return [2 /*return*/, null];
                case 3: return [2 /*return*/];
            }
        });
    }); }
};
