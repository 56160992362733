export var overviewContainer = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "1.5em 0"
};
export var innerContainer = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    backgroundColor: "rgba(255, 255, 255, 1)",
    padding: "1.5em",
    border: "1px solid rgba(235, 235, 235, 1)",
    borderRadius: "4px",
    margin: "0 24px"
};
export var cardContainer = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    gap: "1em"
};
