var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { EstimatedImpact } from "./EstimatedImpact";
import { Property } from "@/utilities/decorators/property";
export var Entity = (_class = /** @class */ (function (_super) {
    __extends(Entity, _super);
    function Entity() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.entityRecommendationId = (function () { return _initializerWarningHelper(_descriptor, _this); })(); //  uuid
        _this.recommendationId = (function () { return _initializerWarningHelper(_descriptor2, _this); })(); // uuid
        _this.franchiseId = (function () { return _initializerWarningHelper(_descriptor3, _this); })();
        _this.entityId = (function () { return _initializerWarningHelper(_descriptor4, _this); })();
        _this.entityType = (function () { return _initializerWarningHelper(_descriptor5, _this); })();
        _this.status = (function () { return _initializerWarningHelper(_descriptor6, _this); })();
        _this.submissionDate = (function () { return _initializerWarningHelper(_descriptor7, _this); })();
        _this.submissionFlag = (function () { return _initializerWarningHelper(_descriptor8, _this); })();
        _this.estimatedImpact = (function () { return _initializerWarningHelper(_descriptor9, _this); })();
        _this.submittedBy = (function () { return _initializerWarningHelper(_descriptor10, _this); })();
        _this.storeCount = (function () { return _initializerWarningHelper(_descriptor11, _this); })();
        return _this;
    }
    return Entity;
}(EstimatedImpact)), _descriptor = _applyDecoratedDescriptor(_class.prototype, "entityRecommendationId", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "recommendationId", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "franchiseId", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "entityId", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "entityType", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "status", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "submissionDate", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "submissionFlag", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "estimatedImpact", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "submittedBy", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "storeCount", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _class);
