var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import _, { camelCase } from "lodash";
import moment from "moment";
import { getMemberVariables } from "../models/utilities";
import { appendDollarSign } from "@/lib/utilities";
var ModelMapper = /** @class */ (function () {
    function ModelMapper() {
    }
    ModelMapper.toModel = function (object, classReference) {
        var keys = getMemberVariables(classReference);
        var model = {};
        Object.entries(object).forEach(function (_ref) {
            var key = _ref[0], value = _ref[1];
            var camelCaseKey = camelCase(key);
            if (keys.includes(camelCaseKey)) {
                if (key.includes("date") && typeof value === "string") {
                    var newValue = moment(value).format("ll") || "";
                    model[camelCaseKey] = newValue;
                }
                else if (key.includes("cmpr_store_dstc_miles") && typeof value === "number") {
                    model[camelCaseKey] = Number(value.toFixed(1));
                }
                else if (key.includes("cmpr_category") || key.includes("cmpr_item_name") || key.includes("cmpr_store_name")) {
                    var capitalized = _.startCase(_.toLower(value));
                    model[camelCaseKey] = capitalized;
                }
                else if (key.includes("total_currency") || key.includes("estimated_total_currency") || key.includes("estimated_direct_currency_low") || key.includes("estimated_direct_currency_high") || key.includes("ewmi_low") || key.includes("ewmi_high") || key.includes("agmi")) {
                    var formatted = typeof value === "number" ? Math.trunc(value) : value;
                    var currency = appendDollarSign(formatted);
                    if (currency.endsWith(".00")) {
                        currency = currency.replace(".00", "");
                    }
                    model[camelCaseKey] = currency;
                }
                else if (key.includes("cmpr_item_price") || key.includes("price_change_current") || key.includes("current_price")) {
                    var currency = appendDollarSign(value);
                    model[camelCaseKey] = currency;
                }
                else {
                    model[camelCaseKey] = value;
                }
            }
        });
        return model;
    };
    ModelMapper.flatten = function (object) {
        if (!object) {
            return {};
        }
        var flattenedObj = {};
        Object.keys(object).forEach(function (key) {
            if (typeof object[key] === "object") {
                var nestedObject = ModelMapper.flatten(object[key]);
                flattenedObj = __assign(__assign({}, flattenedObj), nestedObject);
            }
            else {
                flattenedObj[key] = object[key];
            }
        });
        return flattenedObj;
    };
    return ModelMapper;
}());
export { ModelMapper };
