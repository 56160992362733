// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-table-container {
  .data-table {
    border-collapse: separate;
    border-spacing: 0 2px;

    max-height: inherit !important;
    .data-table-header {
      height: 48px;
      margin-bottom: 8px;
      background-color: "#0E336A0A";
      .header-label {
        font-size: 14px;
        font-weight: 600;
      }
    }
    .sticky-header-row {
      position: sticky;
      top: 0;
      background: white;
      z-index: 1;
    }
    .sticky-header-row:nth-child(2) {
      top: 40px;
    }

    .data-table-body {
      .data-table-row-main-view {
        height: 60px;
        margin-bottom: 5px;
        width: 100%;
      }
      .data-table-row-main-view:nth-child(even) {
        background-color: #f00;
      }
      .data-table-row-nested-view {
        border-radius: 4px;
        height: 76px;
        margin-bottom: 5px;
        width: 100%;
      }
      .table-loss-value {
        color: #bf360c;
      }
      .table-profit-value {
        color: #26890d;
      }
    }
  }
  .data-table-pagination {
    height: 56px;
    padding: 8px 0px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/DataTable/DataTable.css"],"names":[],"mappings":"AAAA;EACE;IACE,yBAAyB;IACzB,qBAAqB;;IAErB,8BAA8B;IAC9B;MACE,YAAY;MACZ,kBAAkB;MAClB,6BAA6B;MAC7B;QACE,eAAe;QACf,gBAAgB;MAClB;IACF;IACA;MACE,gBAAgB;MAChB,MAAM;MACN,iBAAiB;MACjB,UAAU;IACZ;IACA;MACE,SAAS;IACX;;IAEA;MACE;QACE,YAAY;QACZ,kBAAkB;QAClB,WAAW;MACb;MACA;QACE,sBAAsB;MACxB;MACA;QACE,kBAAkB;QAClB,YAAY;QACZ,kBAAkB;QAClB,WAAW;MACb;MACA;QACE,cAAc;MAChB;MACA;QACE,cAAc;MAChB;IACF;EACF;EACA;IACE,YAAY;IACZ,gBAAgB;EAClB;AACF","sourcesContent":[".data-table-container {\n  .data-table {\n    border-collapse: separate;\n    border-spacing: 0 2px;\n\n    max-height: inherit !important;\n    .data-table-header {\n      height: 48px;\n      margin-bottom: 8px;\n      background-color: \"#0E336A0A\";\n      .header-label {\n        font-size: 14px;\n        font-weight: 600;\n      }\n    }\n    .sticky-header-row {\n      position: sticky;\n      top: 0;\n      background: white;\n      z-index: 1;\n    }\n    .sticky-header-row:nth-child(2) {\n      top: 40px;\n    }\n\n    .data-table-body {\n      .data-table-row-main-view {\n        height: 60px;\n        margin-bottom: 5px;\n        width: 100%;\n      }\n      .data-table-row-main-view:nth-child(even) {\n        background-color: #f00;\n      }\n      .data-table-row-nested-view {\n        border-radius: 4px;\n        height: 76px;\n        margin-bottom: 5px;\n        width: 100%;\n      }\n      .table-loss-value {\n        color: #bf360c;\n      }\n      .table-profit-value {\n        color: #26890d;\n      }\n    }\n  }\n  .data-table-pagination {\n    height: 56px;\n    padding: 8px 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
