export function getPricePerformanceBreadcrumbs(recommendationId, tierId, storeId) {
    var baseCrumb = {
        id: 1,
        label: "Price Performance",
        link: "/price-performance"
    };
    if (!tierId) {
        return [baseCrumb];
    }
    var tierCrumb = {
        id: 2,
        label: "Tier ".concat(tierId),
        link: "/tier-performance?recommendationId=".concat(recommendationId, "&tierId=").concat(tierId)
    };
    if (!storeId) {
        return [baseCrumb, tierCrumb];
    }
    var storeCrumb = {
        id: 3,
        label: "Store ".concat(storeId),
        link: "/store-performance?recommendationId=".concat(recommendationId, "&tierId=").concat(tierId, "&storeId=").concat(storeId)
    };
    return [baseCrumb, tierCrumb, storeCrumb];
}
