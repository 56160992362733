import axios from "axios";
import Cookies from "js-cookie";
import { errorHandling } from "../../errorhandler";
import logger from "../../logger";
export var api = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_SERVER_HOST || "https://api.tacobellpricinganalyzer.com/",
    timeout: 10000
});
api.interceptors.request.use(function (config) {
    var token = Cookies.get("access_token");
    if (token) {
        config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
});
// defining a custom error handler for all APIs
var errorHandler = function (error) {
    var _a;
    var statusCode = (_a = error.response) === null || _a === void 0 ? void 0 : _a.status;
    // logging only errors that are not 401
    if (statusCode && statusCode !== 401) {
        logger.error(error);
    }
    errorHandling(error);
    return Promise.reject(error);
};
// registering the custom error handler to the
// 'api' axios instance
api.interceptors.response.use(undefined, function (error) {
    return errorHandler(error);
});
