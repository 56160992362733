// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.competitor-details-table {
  height: 416px;
  overflow-y: auto;
  box-shadow: none;
  scrollbar-gutter: stable both-edges;
}

.competitor-details-table::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.competitor-details-table::-webkit-scrollbar-track {
  background: transparent;
}

.competitor-details-table::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 10px;
}
.check-box-icon {
  font-size: 1.5em !important;
  margin: 0px !important;
}
`, "",{"version":3,"sources":["webpack://./src/views/CompetitorPrice/CompetitorDetails/CompetitorDetails.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,gBAAgB;EAChB,mCAAmC;AACrC;;AAEA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,2BAA2B;EAC3B,sBAAsB;AACxB","sourcesContent":[".competitor-details-table {\n  height: 416px;\n  overflow-y: auto;\n  box-shadow: none;\n  scrollbar-gutter: stable both-edges;\n}\n\n.competitor-details-table::-webkit-scrollbar {\n  width: 8px;\n  height: 8px;\n}\n\n.competitor-details-table::-webkit-scrollbar-track {\n  background: transparent;\n}\n\n.competitor-details-table::-webkit-scrollbar-thumb {\n  background: #d9d9d9;\n  border-radius: 10px;\n}\n.check-box-icon {\n  font-size: 1.5em !important;\n  margin: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
