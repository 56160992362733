var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { Property } from "@/utilities/decorators/property";
export var RecommendationSummary = (_class = /** @class */ (function () {
    function RecommendationSummary() {
        var _this = this;
        this.storeSales = (function () { return _initializerWarningHelper(_descriptor, _this); })();
        this.storeMargin = (function () { return _initializerWarningHelper(_descriptor2, _this); })();
        this.storeTransactionCount = (function () { return _initializerWarningHelper(_descriptor3, _this); })();
        this.performancePeriodStart = (function () { return _initializerWarningHelper(_descriptor4, _this); })();
        this.performancePeriodEnd = (function () { return _initializerWarningHelper(_descriptor5, _this); })();
    }
    return RecommendationSummary;
}()), _descriptor = _applyDecoratedDescriptor(_class.prototype, "storeSales", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "storeMargin", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "storeTransactionCount", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "performancePeriodStart", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "performancePeriodEnd", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _class);
