var colors = {
    fontColor: "#212121",
    inputDefault: "#595959"
};
export var sxStyles = {
    width: "320px",
    paddingRight: 0,
    ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#9e9e9e" // normal state
    },
    ".MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#9249E4" // hover state
    },
    ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000000" // focused state
    },
    label: {
        color: "colors.inputDefault",
        ".Mui-focused": {
            color: colors.inputDefault // focused state
        }
    },
    input: {
        color: colors.fontColor
    },
    svg: {
        color: colors.inputDefault
    }
};
export var listboxStyles = {
    maxHeight: "12rem",
    ".MuiAutocomplete-option": {
        color: colors.fontColor
    },
    ".MuiAutocomplete-option.Mui-focused": {
        backgroundColor: "#f5f5f5"
    },
    '.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: "#E8F6FC !important"
    }
};
