import { Box, IconButton, styled } from "@mui/material";
export var storeTierTableContainerStyles = {
    background: "#fff",
    padding: "16px",
    border: "2px solid #ebebeb",
    borderRadius: "8px"
};
export var TableHeaderContainer = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "var(--Spacing-Spacing-L, 16px)",
    height: 64,
    paddingTop: 8,
    paddingBottom: 16
});
export var ShowCustomNameContainer = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 2,
    height: 40
});
export var RenameTierLink = styled(IconButton)({
    fontFamily: "Open Sans",
    fontSize: 14,
    color: "#46137F",
    fontWeight: 600,
    letterSpacing: "0.8px",
    lineHeight: "21.28px",
    display: "flex",
    gap: 10
});
export var StoreTierTableStyle = {
    header: {
        maxHeight: "40px",
        borderBottom: "none"
    },
    body: {
        maxHeight: "40px",
        borderBottom: "1px solid #CFD8DC"
    }
};
