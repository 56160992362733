var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;
function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
import { Property } from "@/utilities/decorators/property";
export var ProductMargin = (_class = /** @class */ (function () {
    function ProductMargin() {
        var _this = this;
        this.id = (function () { return _initializerWarningHelper(_descriptor, _this); })();
        this.storeId = (function () { return _initializerWarningHelper(_descriptor2, _this); })();
        this.recommendationRoundId = (function () { return _initializerWarningHelper(_descriptor3, _this); })();
        this.franchiseId = (function () { return _initializerWarningHelper(_descriptor4, _this); })();
        this.itemId = (function () { return _initializerWarningHelper(_descriptor5, _this); })();
        this.itemName = (function () { return _initializerWarningHelper(_descriptor6, _this); })();
        this.category = (function () { return _initializerWarningHelper(_descriptor7, _this); })();
        this.newPrice = (function () { return _initializerWarningHelper(_descriptor8, _this); })();
        this.oldPrice = (function () { return _initializerWarningHelper(_descriptor9, _this); })();
        this.ewmiHigh = (function () { return _initializerWarningHelper(_descriptor10, _this); })();
        this.ewmiLow = (function () { return _initializerWarningHelper(_descriptor11, _this); })();
        this.agmi = (function () { return _initializerWarningHelper(_descriptor12, _this); })();
        this.estimateAchieved = (function () { return _initializerWarningHelper(_descriptor13, _this); })();
        this.unitsSold = (function () { return _initializerWarningHelper(_descriptor14, _this); })();
    }
    return ProductMargin;
}()), _descriptor = _applyDecoratedDescriptor(_class.prototype, "id", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "storeId", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "recommendationRoundId", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "franchiseId", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "itemId", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "itemName", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "category", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "newPrice", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "oldPrice", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "ewmiHigh", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "ewmiLow", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "agmi", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "estimateAchieved", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "unitsSold", [Property], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
}), _class);
