var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var emptyStateStyles = {
    backgroundColor: "#fff",
    border: "2px solid #ebebeb",
    borderRadius: "8px",
    width: "100%",
    alignItems: "center",
    justifyContent: "center"
};
export var messageStyles = {
    textAlign: "center"
};
export var boldMessageStyles = __assign(__assign({}, messageStyles), { fontWeight: 600 });
export var mainMessageStyles = function (fontSize) { return (__assign(__assign(__assign({}, messageStyles), boldMessageStyles), { marginBottom: "0.2em", fontSize: fontSize })); };
